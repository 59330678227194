import moment from "moment-timezone";
import { RPM_PATIENT_STATUS } from "../constants";

export const welcomeMessageFieldsEnum = {
  welcomeMessageText: {
    fieldId: 'welcomeMessageText',
    required: () => { return true },
    readOnly: (_, stage) => { return stage !== RPM_PATIENT_STATUS.activated },
  },
  welcomeMessageDate: {
    fieldId: 'welcomeMessageDate',
    required: message => { return message.set },
    readOnly: (_, stage) => { return stage !== RPM_PATIENT_STATUS.activated },
  },
};

export const welcomeMessageToDTO = (message, defaultWelcomeMessage, patient, kitId) => {
  return {
    user_id: patient.id,
    kit_id: kitId,
    message: message.text !== defaultWelcomeMessage ? message.text : undefined,
    schedule_at: calcWelcomeMessageDateTime(message.date, message.time, message.timeAmPm, patient.timezone),
  };
};

export const calcWelcomeMessageDateTime = (date, time, ampm, timezone) => {
  if (date && time && ampm) {
    const t = moment(time, 'hh:mm').format('HH:mm');
    const d = moment.tz(date, timezone).format('YYYY-MM-DD');
    return moment.tz(`${d} ${t} ${ampm}`, 'YYYY-MM-DD HH:mm A', timezone).format();
  }
  return null;
};
