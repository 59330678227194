export const addListItem = (list, setter) => {
  setter(p => ({
    ...p,
    [list]: p[list].concat({}),
  }));
};

export const editListItem = (event, list, index, setter) => {
  const field = event.target.name;
  const value = event.target.value;
  setter(p => ({
    ...p,
    [list]: p[list].map((h, i) => {
      if (i === index) {
        return {
          ...h,
          [field]: value,
        };
      }
      return h;
    }),
  }));
};

export const removeListItem = (list, index, setter) => {
  setter(p => ({
    ...p,
    [list]: p[list].filter((_, i) => i !== index),
  }));
};