import moment from 'moment-timezone';

import Strings from '../../Strings';
import { removeNonErrors } from './rpmPatient';
import { calcWelcomeMessageDateTime, welcomeMessageFieldsEnum } from '../welcomeMessageFields';


export const validateWelcomeMessage = (welcomeMessage, timezone) => {
  const errors = validateWelcomeMessageText(welcomeMessage.text)
    .concat(validateWelcomeMessageDateTime(welcomeMessage, timezone))
  return errors;
};

export function validateWelcomeMessageText(text) {
  const ret = [];
  if (!text) {
    ret.push({ property: 'welcomeMessageText', propertyName: Strings.capPatient.welcomeMessage, errors: [], missing: welcomeMessageFieldsEnum.welcomeMessageText.required() });
  }
  return removeNonErrors(ret);
};

export function validateWelcomeMessageDateTime(message, timezone) {
  const ret = [];
  if (!message.dateTime) {
    ret.push({ property: 'welcomeMessageDate', propertyName: Strings.capPatient.welcomeMessageScheduledDate, errors: [], missing: welcomeMessageFieldsEnum.welcomeMessageDate.required(message) });
  }

  if (message.dateTime && moment(message.dateTime).unix() < moment().unix()) {
    ret.push({ property: 'welcomeMessageDate', propertyName: Strings.capPatient.welcomeMessageScheduledDate, errors: [Strings.errors.dateInThePast], missing: false });
  }
  return removeNonErrors(ret);
};
