import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-date-picker';
import moment from 'moment-timezone';
import 'react-date-picker/dist/DatePicker.css';

import { getTzOffset, isValidDate } from '../../utils';

function DatePicker(props) {
  const { format, value, onChange, timezone, id, className } = props;
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const year1900 = new Date(1900, 0, 0, 0, 0, 0, 0);

  const valueForInput =
    value && isValidDate(value) && value < year1900
      ? value
      : value && isValidDate(value)
      ? new Date(moment(value.getTime() - getTzOffset(value, browserTz) + getTzOffset(value, timezone)).format())
      : '';

  const [key, setKey] = useState(0);

  const onBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setKey(k => k + 1);
    }
  };

  const onDateChange = date => {
    if (!date) {
      onChange('');
      return;
    }
    if (date < year1900) {
      onChange(date);
      return;
    }

    const tzDate = moment(date.getTime() + getTzOffset(date, browserTz) - getTzOffset(date, timezone)).format();
    onChange(new Date(tzDate));
  };

  return (
    <div id={id} onBlur={onBlur}>
      <ReactDatePicker
        key={key}
        value={valueForInput}
        format={format}
        onChange={onDateChange}
        disableCalendar
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        yearAriaLabel="Year"
        className={className}
        disabled={props.disabled}
      />
    </div>
  );
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
  timezone: PropTypes.string,
  id: PropTypes.any.isRequired,
};

export default DatePicker;
