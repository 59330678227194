import moment from "moment-timezone";

import { RPM_PATIENT_STATUS } from "../constants";
import { getSubprogramForApi } from "./cmsPrograms";

const magicDateToClearEndDate = '2300-01-01T00:00:00Z';
        
export const enrollmentFieldsEnum = {
  subprogram: {
    fieldId: 'subprogram',
    required: () => { return true },
    readOnly: (_, stage) => { return stage !== RPM_PATIENT_STATUS.verified },
    getPropertyForDTO: () => 'subprogram',
    toDTO: val => getSubprogramForApi(val.subprogram),
  },
  conditionId: {
    fieldId: 'conditionId',
    getPropertyForDTO: () => 'condition_id',
    required: () => { return true },
    readOnly: (_, stage) => { return stage !== RPM_PATIENT_STATUS.verified },
  },
  enrollmentStart: {
    fieldId: 'enrollmentStart',
    required: (_, stage) => { return stage && (stage === RPM_PATIENT_STATUS.activated) },
    getPropertyForDTO: () => 'start_date',
    toDTO: (val, orgTimezone) => val.enrollmentStart
     ? moment
        .tz(val.enrollmentStart, orgTimezone)
        .startOf('day')
        .format()
      : undefined,
  },
  enrollmentEnd: {
    fieldId: 'enrollmentEnd',
    required: () => { return false },
    getPropertyForDTO: () => 'end_date',
    toDTO: (val, orgTimezone) => val.enrollmentEnd
      ? moment
          .tz(val.enrollmentEnd, orgTimezone)
          .startOf('day')
          .format()
      : magicDateToClearEndDate,
  },
  kit_id: {
    fieldId: 'kit_id',
    required: (_, stage) => { return stage && (stage === RPM_PATIENT_STATUS.activated) },
  },
  enrollmentConsents: {
    fieldId: 'enrollmentConsents',
    getPropertyForDTO: e => e.verbalConsent ? 'verbal_consent' : e.written_consent ? 'written_consent' : 'consent',
    toDTO: e => e.verbalConsent ? true : e.written_consent ? true : false,
    required: () => { return true },
    readOnly: (_, stage) => { return stage !== RPM_PATIENT_STATUS.verified },
  },
  default_program: {
    fieldId: 'default_program',
    required: () => { return false },
  },
  care_provider: {
    fieldId: 'care_provider',
    required: () => { return false },
  },
};

export const enrollmentToDTO = (enrollment, orgTimezone) => {
  return Object.values(enrollmentFieldsEnum).reduce((acc, field) => {
    const value = enrollment[field.fieldId];
      acc[field.getPropertyForDTO ? field.getPropertyForDTO(enrollment) : field.fieldId] = field.toDTO ? field.toDTO(enrollment, orgTimezone) : value;
    return acc;
  }, {});
};

export const fixNewEnrollmentCondition = (enrollment, newConditions) => {
  if (!enrollment?.conditionObject || newConditions.length === 0) {
    return enrollment;
  }
  const conditionId = newConditions.find(nc => nc.code === enrollment.conditionObject.code)?.id;
  
  return {
    ...enrollment,
    conditionId: conditionId,
  }
}