import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { AsyncMultiSelectField, TextArea } from '../../containers/Form';
import { actions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import Strings from '../../Strings';
import { SelectedOptions } from '../../components/Select/Multiselect';
import { renderPatientMedication } from '../../pages/SuperUser/Patients/PatientInfo/MedicalHistory';

export const getLabelTextForCondition = option => {
  return `${option.code ? '* ' : ''}${option.condition_icd10cm || option.code ? `(${option.condition_icd10cm || option.code}) ` : ''}${option.label}`
};


function MedicalHistoryPage(props) {
  const { patient, highlightInvalidFields, validationErrors, fields, setPatient } = props;

  const loadOptionsConditions = _.debounce((value, callback) => {
    if (value?.length < 3) {
      return;
    }
    setTimeout(() => {
      props.getIdcConditions({ search: value }).then(resp => {
        callback(() => {
          if (!resp.response.data || resp.response.data.length === 0) {
            return [];
          }
          const idcConditions = resp.response.data.map(e => {
            return {
              ...e,
              value: e.code,
              label: e.title,
            };
          });
          const orgConditions = props.conditions.filter(c => c.title.includes(value) || c.condition_icd10cm?.includes(value)).map(c => ({ ...c, value: c.id, label: c.title })) || [];
          return orgConditions.concat(idcConditions.filter(d => !props.conditions.some(p => p.condition_icd10cm === d.code)));
        });
      });
    }, 1000);
  }, 1000);

  const getLabelForCondition = option => (
    <React.Fragment key={`option_${option.value}_${patient.conditionsObjects?.some(c => c.value === option.value)}`}>
      {getLabelTextForCondition(option)}
      <input type="checkbox" defaultChecked={patient.conditionsObjects?.some(c => c.value === option.value)} disabled={option.isDisabled} />
    </React.Fragment>
  );

  const onMedicationsChange = event => {
    const { name, value } = event.target;
    setPatient(p => ({
      ...p,
      [name]: value.split('\n'),
    }));
  };

  const medicalHistoryPage = (
    <React.Fragment>
      {props.conditions && (
        <AsyncMultiSelectField
          name="conditions"
          id="conditions"
          label={Strings.condition}
          placeholder={Strings.selectCondition}
          value={patient.conditionsObjects || []}
          defaultOptions={props.conditions.map(c => ({ ...c, value: c.id, label: c.title })).concat(patient.conditionsObjects?.filter(o => o.code) || [])}
          loadOptions={loadOptionsConditions}
          onChange={options => setPatient(p => ({ ...p, conditionsObjects: options }))}
          isRequired={fields.conditions.required}
          disabled={fields.conditions.readOnly}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'conditions')?.errors || []}
          checkboxFirst
          rowClassName="double-width-left"
          isSearchable
          getLabel={getLabelForCondition}
        />
      )}
      <div className="control-with-top-right-text double-width-right" style={{gridRow: 'span 2'}}>
        <TextArea
          name="medications"
          id="medications"
          rows={5}
          value={patient.medications?.join('\n') || ''}
          label={Strings.medications}
          onChange={onMedicationsChange}
          isRequired={fields.medications.required}
          disabled={fields.medications.readOnly}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'medications')?.errors || []}
        />
        <div
          className="right-top-text"
          data-tooltip-content={Strings.capPatient.medicationsTooltip}
          data-tooltip-id="tooltip"
        >
          <span className="icon">?</span>
        </div>
      </div>
      {patient.conditionsObjects?.length > 0 && props.conditions ? (
        <SelectedOptions
          className="double-width-left"
          items={patient.conditionsObjects?.map(c => {return {...c, label: getLabelTextForCondition(c)}}) || []}
          onRemove={e => setPatient(p => ({ ...p, conditionsObjects: p.conditionsObjects.filter(h => h.value !== e.value) }))}
          itemsPerRow={1}
        />
      ) : (
        <div>{Strings.noConditionsSelectFielded}</div>
      )}
      {patient.medicationsWithExtraData?.length > 0 && (
        <div className="double-width-right">{patient.medicationsWithExtraData.map(m => renderPatientMedication(m))?.join('\n') || ''}</div>
      )}
    </React.Fragment>
  );

  return medicalHistoryPage;
}

const mapDispatchToProps = dispatch => ({
  getIdcConditions: pageRequest => dispatch(actions.getIdcConditions(pageRequest)),
});

export default connect(null, mapDispatchToProps)(MedicalHistoryPage);
