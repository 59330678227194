import React from 'react';

import { Input, PhoneInputField, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import { addListItem, editListItem, removeListItem } from './helpers';

function CenterDetailsPage(props) {
  const { patient, highlightInvalidFields, validationErrors, fields, setPatient } = props;

  const centerDetailsPage = (
    <React.Fragment>
      {props.facilities && props.facilities.length > 0 && (
        <SelectField
          name="facilityId"
          id="facilityId"
          label={Strings.providerFacility}
          value={patient.facilityId}
          onChange={option => setPatient(p => ({ ...p, facilityId: option.value }))}
          placeholder={Strings.selectFacility}
          data={props.facilities.map(f => {
            return { value: f.id, label: f.title };
          })}
          isRequired={fields.facilityId.required}
          disabled={fields.facilityId.readOnly}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'facilityId')?.errors || []}
        />
      )}
      {patient.centerDetails &&
        patient.centerDetails.length > 0 &&
        patient.centerDetails.map((c, i) => (
          <React.Fragment key={`centerDetails_${i}`}>
            {i > 0 && <div />}
            <Input
              name="orderingProvider"
              id="orderingProvider"
              label={Strings.capPatient.orderingProvider}
              type="text"
              value={c.orderingProvider || ''}
              onChange={e => editListItem(e, 'centerDetails', i, setPatient)}
              isRequired={fields.centerDetails.required}
              disabled={fields.centerDetails.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'centerDetails' && v.index === i && v.field === 'orderingProvider',
                )?.errors || []
              }
            />
            <PhoneInputField
              align="left"
              name="clinicContact"
              id="clinicContact"
              label={Strings.capPatient.clinicContact}
              type="text"
              value={c.clinicContact || ''}
              onChange={e => editListItem({ ...e, target: { ...e.target, name: 'clinicContact' } }, 'centerDetails', i, setPatient)}
              isRequired={fields.centerDetails.required}
              disabled={fields.centerDetails.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'centerDetails' && v.index === i && v.field === 'clinicContact',
                )?.errors || []
              }
            />
            <div className="button-container mt-20">
              <button
                className="minus-button high"
                onClick={() => removeListItem('centerDetails', i, setPatient)}
                disabled={patient.centerDetails.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('centerDetails', setPatient)}></button>
      </div>
    </React.Fragment>
  );

  return centerDetailsPage;
}

export default CenterDetailsPage;
