import React from 'react';

import { SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import kitIdImg from '../../images/kitId.png';

const KitPage = props => {
  const { enrollment, setEnrollment, validationErrors, highlightInvalidFields, fields, kits } = props;

  const [showKitPicture, setShowKitPicture] = React.useState(false);

  const kitPage = (
    <React.Fragment>
      <div className="control-with-top-right-text double-width-left">
        <SelectField
          id="kit_id"
          name="kit_id"
          label={Strings.kitId}
          placeholder={Strings.scanBarcodeOrEnterId}
          value={enrollment?.kit_id}
          data={kits}
          onChange={option => setEnrollment({ ...enrollment, kit_id: option.value })}
          isSearchable
          isRequired={fields.kit_id.required}
          disabled={fields.kit_id.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'kit_id')?.errors || []}
          highlightInvalid={highlightInvalidFields}
          rowClassName="double-width-left"
        />
        <div className="right-top-text">
          <span className="label">{Strings.capPatient.whereIsKitId}</span>
          <span
            onMouseEnter={() => setShowKitPicture(true)}
            onMouseLeave={() => setShowKitPicture(false)}
            className="icon"
          >
            ?
          </span>
        </div>
      </div>

      {showKitPicture && (
        <div className="kit-location">
          <img src={kitIdImg} className="hwImg" alt="hardware" />
          {Strings.wheresKitIdText}
        </div>
      )}
    </React.Fragment>
  );

  return kitPage;
};

export default KitPage;
