import React from 'react';
import { connect } from 'react-redux';

import { DateInput } from '../../containers/Form';
import Strings from '../../Strings';
import { OnboardStages } from './OnboardPatientWizard';
import { Timezones } from '../../constants';

const EnrollmentDatesPage = props => {
  const { enrollment, setEnrollment, validationErrors, highlightInvalidFields, fields, stage } = props;

  const orgTimezoneInfo = props.timezone !== props.orgTimezone ? ` (${Strings.formatString(Strings.enrollmentDateTimezoneTooltip, Timezones[props.orgTimezone], Strings.organization)})` : '';
  const datesPage = (
    <React.Fragment>
      {enrollment.subprogram && stage !== OnboardStages.settingEndDate && (
        <DateInput
          name="enrollmentStart"
          id="enrollmentStart"
          label={`${Strings.startDate}${orgTimezoneInfo}`}
          placeholder="MM / DD / YYYY"
          value={enrollment.enrollmentStart || ''}
          timezone={props.orgTimezone}
          format="MM / dd / yyyy"
          onChange={value => setEnrollment(e => ({...e, enrollmentStart: value}))}
          highlightInvalid={highlightInvalidFields}
          isRequired={fields.enrollmentStart.required}
          disabled={fields.enrollmentStart.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'enrollmentStart')?.errors || []}
          rowClassName="double-width-left"
        />
      )}
      {enrollment.subprogram && stage === OnboardStages.settingEndDate && (
        <DateInput
          name="enrollmentEnd"
          id="enrollmentEnd"
          label={`${Strings.endDate}${orgTimezoneInfo}`}
          placeholder="MM / DD / YYYY"
          value={enrollment.enrollmentEnd || ''}
          timezone={props.orgTimezone}
          format="MM / dd / yyyy"
          onChange={value => setEnrollment(e => ({...e, enrollmentEnd: value}))}
          highlightInvalid={highlightInvalidFields}
          isRequired={fields.enrollmentEnd.required}
          disabled={fields.enrollmentEnd.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'enrollmentEnd')?.errors || []}
          rowClassName="double-width-left"
        />
      )}
    </React.Fragment>
  );

  return datesPage;
};

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    orgTimezone: state.auth?.ui_config?.timezone,
  };
};

export default connect(mapStateToProps, null)(EnrollmentDatesPage);
