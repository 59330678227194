import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { openModalAction } from '../../../actions/modal';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR, DATE_MONTH_DAY_YEAR_WITH_DASH } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getCapChargeText } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';
import { actions as kitActions } from '../../Kits/redux/actions';
import { GET_DEVICES, PATIENT_DETAILS_RESULT } from './redux/constants';
import { PageHeader } from '../../../components/PageHeader';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import ListOfTables from '../../../containers/ListOfTables';
import { AUTHORIZE_DEXCOM_RESULT, GET_DEXCOM_CONFIG_RESULT, dexcomActions } from './redux/dexcomActions';
import { DeviceClass, DeviceTypes } from '../../../utils/deviceTypes';

//#region exports
export const calculateDeviceId = device => {
  return device.deviceId.replace(`${device.manufacturer}.`, '').replace(`${device.model}.`, '');
};

export const navigateToDevice = (device, url, patientId, onNavigate) => {
  const deviceType = device.deviceType;
  const deviceId = device.deviceId;
  const capId = device.cap_id;
  let model = device.model;
  let manufacturer = device.manufacturer;
  if (model == '') {
    if (manufacturer.startsWith('gg-rpm')) {
      model = manufacturer.replace('gg-rpm ', '');
      manufacturer = 'gg-rpm';
    }
  }
  const lastActivity = device.lastEventDate ? moment(device.lastEventDate).unix() : moment().unix();

  if (deviceId) {
    if (
      DeviceTypes[deviceType].deviceClass === DeviceClass.hub ||
      DeviceTypes[deviceType].deviceClass === DeviceClass.cap ||
      DeviceTypes[deviceType].deviceClass === DeviceClass.ble
    ) {
      onNavigate(`${url}/${DeviceTypes[deviceType].id}/${deviceId}`);
    } else if (DeviceTypes[deviceType].deviceClass === DeviceClass.thirdParty) {
      onNavigate(
        `${url}/3rdParty/${deviceId}/${manufacturer}/${model}/${encodeURIComponent(DeviceTypes[deviceType].id)}/details?lastActivity=${lastActivity}&patientId=${patientId}`,
      );
    } else {
      onNavigate('/device-under-construction');
    }
  } else {
    onNavigate(`${url}/${capId}/${deviceType}/details`);
  }
};

//#endregion

function PatientDevices(props) {
  const searchTime = React.useRef();
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    limit: isMobile ? 100 : undefined,
    status: 'active',
  });
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICES_EDIT);
  const patientId = props.patientId || props.userId;

  useEffect(() => {
    if (patientId !== props.patientId) {
      if (props.dexcomCode) {
        if (props.dexcomPatient !== patientId.toString()) {
          showDexcomInvalidPatient();
        } else {
          props.authorizeDexcom({ code: props.dexcomCode, patientId }).then(resp => {
            if (resp.type === AUTHORIZE_DEXCOM_RESULT) {
              props.getDevices(pageRequest.current, patientId);
              showDexcomDeviceConfigured();
            } else {
              showDexcomConfigurationFailed();
            }
          });
        }
      } else {
        props.getPatientInfo(patientId).then(resp => {
          if (resp.type === PATIENT_DETAILS_RESULT) {
            if (
              (resp?.response?.dexcomStatus === 'new' || resp?.response?.dexcomStatus === 'revoked') &&
              resp.response?.kitId
            ) {
              props.getDexcomConfig().then(resp1 => {
                if (resp1.type === GET_DEXCOM_CONFIG_RESULT) {
                  showDexcomInvite(resp1.response, resp?.response?.dexcomStatus);
                }
              });
            }
          }
        });
      }
    }
    props.getDevices(pageRequest.current, patientId);
    if (!props.kit?.id) {
      props.getPatientKitInfo(patientId);
    }
    return () => {
      props.clearDevice();
    };
  }, [patientId]);

  const showDexcomDeviceConfigured = () => {
    const data = {
      title: <span>{Strings.dexcomDeviceConfiguredSuccess}</span>,
      caption: Strings.dexcomDeviceConfiguration,
      onCancelAction: null,
      hideCaution: true,
      noButtonText: Strings.ok,
      hideYesButton: true,
    };
    props.openConfirmModal(data);
  };

  const showDexcomConfigurationFailed = () => {
    const data = {
      title: <span>{Strings.dexcomConfigurationFailedText}</span>,
      caption: Strings.dexcomConfigurationFailed,
      onCancelAction: null,
      hideCaution: true,
      noButtonText: Strings.ok,
      hideYesButton: true,
    };
    props.openConfirmModal(data);
  };

  const showDexcomInvalidPatient = () => {
    const data = {
      title: <span>{Strings.dexcomCodeNotMatchingPatient}</span>,
      caption: Strings.dexcomInvalidCode,
      onCancelAction: null,
      hideCaution: true,
      noButtonText: Strings.ok,
      hideYesButton: true,
    };
    props.openConfirmModal(data);
  };

  const showDexcomInvite = (config, status) => {
    const { client_id, host, redirect_uri } = config;
    const data = {
      title: <span>{status === 'new' ? Strings.authorizeRxcapForDexcom : Strings.reAuthorizeRxcapForDexcom}</span>,
      caption: Strings.dexcomDeviceConfiguration,
      onConfirmAction: () => {
        window.location = `${host}/v2/oauth2/login?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=offline_access&state=${patientId}`;
      },
      onCancelAction: null,
      hideCaution: true,
      yesButtonText: Strings.ok,
      noButtonText: Strings.notNow,
    };
    props.openConfirmModal(data);
  };

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    clearTimeout(searchTime.current);
    searchTime.current = setTimeout(() => {
      props.getDevices(pageRequest.current, patientId);
    }, 1000);
  };

  const onOffsetChange = (offset, limit) => {
    pageRequest.current.offset = offset;
    if (limit) {
      pageRequest.current.limit = limit;
    }
    props.getDevices(pageRequest.current, patientId);
  };

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.offset = 0;
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;

    props.getDevices(pageRequest.current, patientId);
  };

  const onDeviceDelete = id => {
    const device = props.patientDevices[id];
    const data = {
      title: (
        <span>
          {Strings.deleteHubDeviceWarning} <b>{device.cap_name || device.cap_id}</b>?
        </span>
      ),

      onConfirmAction: kitActions.unassignDeviceFromKit(props.kit.id, device.cap_id, [
        actions.getDevices(pageRequest.current, props.patientId),
      ]),
      onCancelAction: null,
    };
    props.openConfirmModal(data);
  };

  const PatientHeader = () => {
    const { isLoading, pagination, patientDevices } = props;
    const headerTitle =
      !isLoading && patientDevices && pagination
        ? Strings.formatString(
            Strings.showingXDevices,
            patientDevices.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;
    return (
      <React.Fragment>
        <PageHeader
          isBlack
          left={headerTitle}
          right={
            <React.Fragment>
              <TextInput
                class="search"
                placeholder={Strings.search}
                onChange={e => onSearchQueryChange(e.target.value)}
              />
              {canEdit && (
                <button className="dark_blue" onClick={() => props.onAttachKit(pageRequest.current)}>
                  {props.kit ? Strings.reassignKit : Strings.attachKit}
                </button>
              )}
              {props.kit && canEdit && (
                <button className="dark_blue" onClick={() => props.onAttachCap(pageRequest.current)}>
                  {Strings.attachDevice}
                </button>
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  };

  const onRowSelected = id => {
    const device = props.patientDevices[id];
    navigateToDevice(device, `/cap-patients/${patientId}/devices`, patientId, props.onNavigate);
  };

  const renderDeviceId = device => {
    const id = calculateDeviceId(device);
    return id;
  };

  const columns = [
    <Column
      key="cap_id"
      title={Strings.deviceId}
      value={d => renderDeviceId(d)}
      sortKey="cap_id"
      className="clickable"
    />,
    <Column key="cap_name" title={Strings.deviceModel} value={d => `${d.manufacturer} ${d.model}`} />,
    <Column key="deviceType" title={Strings.deviceType} value={d => d.deviceType} />,
    <Column
      key="last_event_time"
      title={Strings.lastEventTime}
      value={d => (d.lastEventDate ? moment(d.lastEventDate).format(DATE_MONTH_DAY_YEAR_WITH_DASH) : '-')}
    />,
    <Column
      key="registrationDate"
      title={Strings.capPatient.registeredDate}
      value={d => (d.registrationDate ? moment.unix(d.registrationDate).format(DATE_MONTH_DAY_YEAR) : '-')}
    />,
    <Column
      key="activationDate"
      title={Strings.capPatient.activationDate}
      value={d => (d.activationDate ? moment.unix(d.activationDate).format(DATE_MONTH_DAY_YEAR) : '-')}
    />,
    <Column key="battery" title={Strings.schedules.battery} value={d => getCapChargeText(d.battery)} />,
  ];

  const { isLoading, pagination, patientDevices } = props;
  const buttons = [
    {
      icon: 'delete',
      onClick: onDeviceDelete,
      disabled: () => {
        return false;
      },
      text: Strings.delete,
    },
  ];

  return (
    <OverlayScrollbarsComponent
      defer
      className="scrollbar-right-margin"
      options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
    >
      {props.patientVersion ? <h2>{Strings.yourDevices}</h2> : PatientHeader()}

      {isBrowser && props.kit?.id && (
        <Table
          isLoading={isLoading}
          name="patient-devices"
          data={patientDevices || []}
          onRowSelection={
            props.patientVersion || !hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICE_DETAILS)
              ? undefined
              : onRowSelected
          }
          onOffsetChange={onOffsetChange}
          onSortClick={onSortClick}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          buttons={canEdit ? buttons : undefined}
        >
          {columns}
        </Table>
      )}
      {isMobile && props.kit?.id && (
        <React.Fragment>
          <ListOfTables
            isLoading={isLoading}
            data={patientDevices || []}
            title={row => row.deviceType}
            rows={columns}
          />
        </React.Fragment>
      )}
    </OverlayScrollbarsComponent>
  );
}

PatientDevices.propTypes = {
  clearDevice: PropTypes.func,
  getDevices: PropTypes.func,
  isLoading: PropTypes.any,
  onAttachCap: PropTypes.func,
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patientDevices: PropTypes.array,
  patientId: PropTypes.any,
  schedules: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  const urlParams = new URLSearchParams(window.location.search);

  return {
    patientDevices: patients && patients?.patientDevices?.data,
    isLoading: patients && patients?.isPatientDevicesLoading,
    pagination: patients && patients?.patientDevices?.pagination,
    userId: state.auth.profile?.id,
    kit: state.entities.kits?.patientKit,
    dexcomCode: urlParams.get('code'),
    dexcomPatient: urlParams.get('state'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNavigate: path => dispatch(push(path)),
  getDevices: (pageRequest, patientId) => dispatch(actions.getDevices(pageRequest, patientId)),
  onAttachCap: req =>
    dispatch(
      openModalAction('attach-cap-to-schedule', {
        action: actions.getDevices,
        actionType: GET_DEVICES,
        caption: Strings.attachDevice,
        request: req, // props.match.params.id,
        patientDevice: true,
        patientId: ownProps.patientId,
        kit: ownProps.kit,
      }),
    ),
  onAttachKit: req =>
    dispatch(
      openModalAction('attach-kit', {
        action: actions.getDevices,
        actionType: GET_DEVICES,
        caption: ownProps.kit ? Strings.reassignKit : Strings.attachKit,
        request: req, // props.match.params.id,
        kit: ownProps.kit,
        patientId: ownProps.patientId,
      }),
    ),
  clearDevice: () => dispatch(actions.clearDevice()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  getDexcomConfig: () => dispatch(dexcomActions.getDexcomConfig()),
  authorizeDexcom: request => dispatch(dexcomActions.authorizeDexcom(request)),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDevices);
