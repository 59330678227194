import { isEsi } from './portalType';
import { store } from './store';

export const PATIENT = 'patient';
export const CAP_MANAGER = 'capmanager';
export const CAP_MANAGER1 = 'capmanager1';
export const CAP_MANAGER2 = 'capmanager2';
export const CAP_MANAGER3 = 'capmanager3';
export const DOCTOR = 'doctor';
export const CARE_PROVIDER = 'care_provider';
export const SUPER_CAP_ADMIN = 'supercapadmin';
export const SHIPPER = 'shipper';
export const SUPERUSER = 'superuser';
export const ORGANIZATION_MANAGER = 'organization_manager';
export const CAREDX = 'CareDx';
export const PORTAL_ESI = 'esi';

export function getRole() {
  return store.getState().auth.role;
}

export function getOrgnization() {
  return store.getState().auth.organization?.name;
}

export function isCareDx() {
  return getOrgnization() === CAREDX;
}

export function isOmron() {
  return getOrgnization() === 'Omron' || getOrgnization() === 'omron';
}

export function isESIOrgName() {
  return getOrgnization() === PORTAL_ESI;
}

export function getRoleFromSessionStorage() {
  return sessionStorage.getItem('role');
}

export function isPatient() {
  return getRole() === PATIENT;
}

export function isDoctor() {
  return getRole() === DOCTOR;
}

export function isCapManager() {
  return (
    getRole() === CAP_MANAGER || getRole() === CAP_MANAGER1 || getRole() === CAP_MANAGER2 || getRole() === CAP_MANAGER3
  );
}

export function isSuperCapAdmin() {
  return getRole() === SUPER_CAP_ADMIN;
}

export function isSuperUser() {
  return getRole() === SUPERUSER;
}

export function isShipper() {
  return getRole() === SHIPPER;
}

export function isOrganizationManager() {
  return getRole() === ORGANIZATION_MANAGER;
}

export function isESIDoctor() {
  return isDoctor() && isEsi();
}

export function isESIPatient() {
  return isPatient() && isESIOrgName();
}

export function isESICapManager() {
  return isCapManager() && isESIOrgName();
}

export function isBrownUniversity() {
  return getOrgnization() === 'Brown University';
}

export function isMe(id) {
  return store.getState().auth.profile.id === id;
}
