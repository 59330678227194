import React from 'react';

import Strings from '../../../Strings';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './Formatting';
import { graphDataProvidersEnum } from './DataProviders';
import { HR_DISTRIBUTON_RESOLUTION } from './Constants';
import { DeviceTypes } from '../../../utils/deviceTypes';

const COLOR_PURPLE = '#444e86';
const COLOR_DARK_GREEN = '#003F5C';
const COLOR_VIOLET = '#955196';
const COLOR_PINK = '#dd5182';
const COLOR_ORANGE = '#ff6e54';
const COLOR_YELLOW = '#ffa600';

export const DEFAULT_DATE_RANGE_DAYS = 20;

export const mobileDataRangeTypes = Object.freeze({
  day: 'day',
  week: 'week',
  twoWeeks: 'twoWeeks',
  month: 'month',
  sixMonths: 'sixMonths',
  year: 'year',
});
export const DEFAULT_MOBILE_DATE_RANGE_UNIT = mobileDataRangeTypes.week;
export const DEFAULT_ALLOWED_MOBILE_DATE_RANGE_UNITS = [
  mobileDataRangeTypes.week,
  mobileDataRangeTypes.month,
  mobileDataRangeTypes.year,
];

export const graphMetricEnum = Object.freeze({
  weight: 'weight',
  weightDailyAvg: 'weightDailyAvg',
  bpSP: 'bpSP',
  bpPP: 'bpPP',
  bpDP: 'bpDP',
  bpPR: 'bpPR',
  hr: 'hr',
  hrDailyAvg: 'hrDailyAvg',
  hrHourlyAvg: 'hrHourlyAvg',
  // hrDailyBar: 'hrDailyBar',
  // hrDailyDistribution: 'hrDailyDistribution',
  sleepDuration: 'sleepDuration',
  awakeDuration: 'awakeDuration',
  deepSleepDuration: 'deepSleepDuration',
  lightSleepDuration: 'lightSleepDuration',
  remDuration: 'remDuration',
  // would require new Y axis render
  // sleepStart: 'sleepStart',
  // sleepStop: 'sleepStop',
  saturation: 'saturation',
  pi: 'pi',
  glucose: 'glucose',
  temperature: 'temperature',
  pm25: 'pm2_5',
  pm1: 'pm1',
  pm10: 'pm10',
  barometricPressure: 'barometricPressure',
  ch2o: 'ch2o',
  co2: 'co2',
  luminocity: 'luminocity',
  noise: 'noise',
  nox: 'nox',
  relativeHumidity: 'relativeHumidity',
  airTemperature: 'airTemperature',
  voc: 'voc',
  pt: 'pt',
  inr: 'inr',
  fev1: 'fev1',
  fvc: 'fvc',
});

export const graphMetrics = Object.freeze([
  {
    id: graphMetricEnum.weight,
    label: Strings.weight,
    group: '',
    dataProvider: graphDataProvidersEnum.weight,
    yAxis: 'around100slow',
    unit: ` ${Strings.unitsEnum.lbs}`,
    format: {
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Weighing Machine'].id],
  },
  {
    id: graphMetricEnum.weightDailyAvg,
    label: Strings.weight + 'DailyAvg',
    group: '',
    dataProvider: graphDataProvidersEnum.weight,
    yAxis: 'around100slow',
    unit: ` ${Strings.unitsEnum.lbs}`,
    format: {
      chartType: 'bar',
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Weighing Machine'].id],
  },
  // #region BP
  {
    id: graphMetricEnum.bpSP,
    label: 'SYS',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.bp,
    yAxis: 'around100',
    unit: ' mmHg',
    format: {
      stroke: '#30E1CF',
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.diamond,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id],
  },
  {
    id: graphMetricEnum.bpPP,
    label: 'MAP',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.bp,
    yAxis: 'around100',
    unit: ' mmHg',
    format: {
      stroke: '#4BD52B',
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.triangle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id],
  },
  {
    id: graphMetricEnum.bpDP,
    label: 'DIA',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.bp,
    yAxis: 'around100',
    unit: ' mmHg',
    format: {
      stroke: '#276AE5',
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id],
  },
  {
    id: graphMetricEnum.bpPR,
    label: 'PR',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.bp,
    yAxis: 'around100',
    unit: ' bpm',
    format: {
      stroke: '#F960D9',
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id],
  },
  {
    id: graphMetricEnum.hr,
    label: 'HR',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.hr,
    yAxis: 'around100',
    unit: 'bpm',
    format: {
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.diamond,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id, DeviceTypes['Wrist Band'].id, DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.hrDailyAvg,
    label: `HR ${Strings.dailyAverage}`,
    group: 'BP',
    dataProvider: graphDataProvidersEnum.hr,
    yAxis: 'around100',
    unit: 'bpm',
    format: {
      stroke: COLOR_DARK_GREEN,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id, DeviceTypes['Wrist Band'].id, DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.hrHourlyAvg,
    label: `HR ${Strings.hourlyAverage}`,
    group: 'BP',
    dataProvider: graphDataProvidersEnum.hr,
    yAxis: 'around100',
    unit: 'bpm',
    format: {
      stroke: COLOR_PINK,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 4',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id, DeviceTypes['Wrist Band'].id, DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.hrDailyBar,
    label: 'HR <DailyBar>',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.hr,
    yAxis: 'around100',
    unit: 'bpm',
    dontSimplify: true,
    format: {
      chartType: 'bar', // 'distribution',
      color: COLOR_DARK_GREEN,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id, DeviceTypes['Wrist Band'].id, DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.hrDailyDistribution,
    label: 'HR Daily Distribution',
    group: 'BP',
    dataProvider: graphDataProvidersEnum.hr,
    yAxis: 'around100',
    unit: 'bpm',
    dontSimplify: true,
    format: {
      chartType: 'distribution',
      resolution: HR_DISTRIBUTON_RESOLUTION,
      color: COLOR_DARK_GREEN,
    },
    devices: [DeviceTypes['Blood Pressure Monitor'].id, DeviceTypes['Wrist Band'].id, DeviceTypes['Pulse Oximeter'].id],
  },
  // #endregion
  // #region Sleep
  {
    id: graphMetricEnum.sleepDuration,
    label: Strings.opportunity,
    group: Strings.sleep,
    dataProvider: graphDataProvidersEnum.sleep,
    yAxis: 'around10',
    unit: Strings.unitsEnum.minutes,
    format: {
      stroke: COLOR_DARK_GREEN,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 4',
      dotShape: scatterDotShapeEnum.plus,
      dotSize: symbolDotSizeEnum.normal,
    },
    devices: [DeviceTypes['Wrist Band'].id],
  },
  {
    id: graphMetricEnum.awakeDuration,
    label: Strings.awake,
    group: Strings.sleep,
    dataProvider: graphDataProvidersEnum.sleep,
    yAxis: 'around10',
    unit: Strings.unitsEnum.minutes,
    format: {
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '2 4',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Wrist Band'].id],
  },
  {
    id: graphMetricEnum.deepSleepDuration,
    label: Strings.deepSleep,
    group: Strings.sleep,
    dataProvider: graphDataProvidersEnum.sleep,
    yAxis: 'around100',
    unit: Strings.unitsEnum.minutes,
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 4',
      dotShape: scatterDotShapeEnum.triangle,
      dotSize: symbolDotSizeEnum.normal,
    },
    devices: [DeviceTypes['Wrist Band'].id],
  },
  {
    id: graphMetricEnum.lightSleepDuration,
    label: Strings.lightSleep,
    group: Strings.sleep,
    dataProvider: graphDataProvidersEnum.sleep,
    yAxis: 'around100',
    unit: Strings.unitsEnum.minutes,
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 2',
      dotShape: scatterDotShapeEnum.star,
      dotSize: symbolDotSizeEnum.normal,
    },
    devices: [DeviceTypes['Wrist Band'].id],
  },
  {
    id: graphMetricEnum.remDuration,
    label: Strings.rem,
    group: Strings.sleep,
    dataProvider: graphDataProvidersEnum.sleep,
    yAxis: 'around10',
    unit: Strings.unitsEnum.minutes,
    format: {
      stroke: COLOR_PINK,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 4',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Wrist Band'].id],
  },
  // {
  //   id: graphMetricEnum.sleepStart,
  //   label: 'Bedtime',
  //   group: Strings.sleep,
  //   dataProvider: graphDataProvidersEnum.sleep,
  //   yAxis: 'around10',
  //   format: {
  //     stroke: '#7a5195',
  //     strokeWidth: 1.5,
  //     type: 'monotone',
  //     strokeDasharray: '',
  //     dotShape: scatterDotShapeEnum.circle,
  //     dotSize: symbolDotSizeEnum.small,
  //   },
  //   devices: [DeviceTypes['Wrist Band'].id],
  // },
  // {
  //   id: graphMetricEnum.sleepStop,
  //   label: 'Awake Time',
  //   group: Strings.sleep,
  //   dataProvider: graphDataProvidersEnum.sleep,
  //   yAxis: 'around10',
  //   format: {
  //     stroke: '#7a5195',
  //     strokeWidth: 1.5,
  //     type: 'monotone',
  //     strokeDasharray: '',
  //     dotShape: scatterDotShapeEnum.circle,
  //     dotSize: symbolDotSizeEnum.small,
  //   },
  //   devices: [DeviceTypes['Wrist Band'].id],
  // },
  // #endregion
  {
    id: graphMetricEnum.saturation,
    label: Strings.saturation,
    group: Strings.pulseOx,
    dataProvider: graphDataProvidersEnum.pulseox,
    yAxis: 'around100slow',
    unit: '%',
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.pi,
    label: Strings.perfusionIndex,
    group: Strings.pulseOx,
    dataProvider: graphDataProvidersEnum.pulseox,
    yAxis: 'around10',
    unit: '%',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '4 4',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Pulse Oximeter'].id],
  },
  {
    id: graphMetricEnum.glucose,
    label: Strings.glucose,
    group: '',
    dataProvider: graphDataProvidersEnum.gluco,
    yAxis: 'around100slow',
    unit: ' mg/dL',
    format: {
      stroke: COLOR_PURPLE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.star,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes.Glucometer.id],
  },
  {
    id: graphMetricEnum.temperature,
    label: Strings.temperature,
    group: '',
    dataProvider: graphDataProvidersEnum.temp,
    yAxis: 'around100slow',
    unit: '°F',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.triangle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes.Thermometer.id],
  },
  // #region AQM
  {
    id: graphMetricEnum.pm25,
    label: (
      <span className="graph-label">
        PM<sub>2.5</sub>
      </span>
    ),
    labelWithUnit: unit => (
      <span>
        PM<sub>2.5</sub> {`${unit ? `[${unit}]` : ''}`}
      </span>
    ),
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1',
    unit: 'µg/m³',
    format: {
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.plus,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.pm1,
    label: (
      <span className="graph-label">
        PM<sub>1</sub>
      </span>
    ),
    labelWithUnit: unit => (
      <span>
        PM<sub>1</sub> {`${unit ? `[${unit}]` : ''}`}
      </span>
    ),
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1',
    unit: 'µg/m³',
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.pm10,
    label: (
      <span className="graph-label">
        PM<sub>10</sub>
      </span>
    ),
    labelWithUnit: unit => (
      <span>
        PM<sub>10</sub> {`${unit ? `[${unit}]` : ''}`}
      </span>
    ),
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1',
    unit: 'µg/m³',
    format: {
      stroke: COLOR_PINK,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.diamond,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.barometricPressure,
    label: Strings.atmocubeValues.atmosphericPressure,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1000slow',
    unit: 'hPa',
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.ch2o,
    label: Strings.atmocubeValues.formaldehyde,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around0_01',
    unit: 'ppm',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.co2,
    label: 'CO₂',
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1000',
    unit: 'ppm',
    format: {
      stroke: COLOR_DARK_GREEN,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.luminocity,
    label: Strings.atmocubeValues.lightIntensity,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around100',
    unit: 'lux',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.star,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.noise,
    label: Strings.atmocubeValues.noiseLevels,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around50',
    unit: 'dB',
    format: {
      stroke: COLOR_PINK,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.nox,
    label: Strings.atmocubeValues.noxIndex,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around1',
    unit: '',
    format: {
      stroke: COLOR_PURPLE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.relativeHumidity,
    label: Strings.atmocubeValues.relativeHumidity,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around100',
    unit: '%',
    format: {
      stroke: COLOR_PURPLE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.airTemperature,
    label: Strings.temperature,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around100',
    unit: '°F',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.plus,
      dotSize: symbolDotSizeEnum.normal,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  {
    id: graphMetricEnum.voc,
    label: Strings.atmocubeValues.vocIndex,
    group: Strings.airQuality,
    dataProvider: graphDataProvidersEnum.airQualityMonitor,
    yAxis: 'around100',
    unit: '',
    format: {
      stroke: COLOR_DARK_GREEN,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.triangle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['Air Quality Monitor'].id],
  },
  // #endregion
  {
    id: graphMetricEnum.pt,
    label: 'PT',
    group: Strings.coagulation,
    dataProvider: graphDataProvidersEnum.ptInr,
    yAxis: 'around10',
    unit: 's',
    format: {
      stroke: COLOR_VIOLET,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['PT/INR Monitor'].id],
  },
  {
    id: graphMetricEnum.inr,
    label: 'INR',
    group: Strings.coagulation,
    dataProvider: graphDataProvidersEnum.ptInr,
    yAxis: 'around1',
    unit: '',
    format: {
      stroke: COLOR_ORANGE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.square,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes['PT/INR Monitor'].id],
  },
  {
    id: graphMetricEnum.fev1,
    label: <span>FEV<sub>1</sub></span>,
    group: Strings.spirometry,
    dataProvider: graphDataProvidersEnum.spirometer,
    yAxis: 'around10',
    unit: ` L`,
    labelWithUnit: unit => (
      <span>
        FEV<sub>1</sub> {`${unit ? `[${unit}]` : ''}`}
      </span>
    ),
    format: {
      stroke: COLOR_YELLOW,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.cross,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes.Spirometer.id],
  },
  {
    id: graphMetricEnum.fvc,
    label: 'FVC',
    group: Strings.spirometry,
    dataProvider: graphDataProvidersEnum.spirometer,
    yAxis: 'around10',
    unit: ` L`,
    format: {
      stroke: COLOR_PURPLE,
      strokeWidth: 1.5,
      type: 'monotone',
      strokeDasharray: '',
      dotShape: scatterDotShapeEnum.diamond,
      dotSize: symbolDotSizeEnum.small,
    },
    devices: [DeviceTypes.Spirometer.id],
  },
]);

export function filterMetricsByDevices(metrics, devices) {
  return metrics.filter(m => m.devices.some(d => devices.includes(d)));
}

export function getMetricsByReadings(readings) {
  switch (readings) {
    case 'Temperature':
      return [graphMetricEnum.temperature];
    case 'PressureDiastolic':
      return [graphMetricEnum.bpDP, graphMetricEnum.bpSP, graphMetricEnum.bpPP, graphMetricEnum.bpPR];
    case 'Weight':
      return [graphMetricEnum.weight];
    case 'OxygenSaturation':
      return [graphMetricEnum.saturation, graphMetricEnum.hr, graphMetricEnum.hrSimplified, graphMetricEnum.pi];
    case 'SugarLevel':
      return [graphMetricEnum.glucose];
    case "ForcedExpiratoryVolume1s":
      return [graphMetricEnum.fev1, graphMetricEnum.fvc];
    // would need data with different resolution, depending on graph zoom
    // case "steps":
    //   return [];
    //case "burned_calories":
    //case "exercise_time":
    case 'Sleep':
      return [
        graphMetricEnum.awakeDuration,
        graphMetricEnum.deepSleepDuration,
        graphMetricEnum.lightSleepDuration,
        graphMetricEnum.remDuration,
        graphMetricEnum.sleepDuration,
        // graphMetricEnum.sleepStart,
        // graphMetricEnum.sleepStop,
      ];
    case 'HeartRate':
      return [
        graphMetricEnum.hr,
        graphMetricEnum.hrSimplified,
        graphMetricEnum.hrDailyAvg,
        graphMetricEnum.hrHourlyAvg,
        graphMetricEnum.hrDailyBar,
        graphMetricEnum.hrDailyDistribution,
        graphMetricEnum.patientsPerProgram,
      ];
    case 'PM2.5':
      return [
        graphMetricEnum.pm25,
        graphMetricEnum.pm1,
        graphMetricEnum.pm10,
        graphMetricEnum.barometricPressure,
        graphMetricEnum.ch2o,
        graphMetricEnum.co2,
        graphMetricEnum.luminocity,
        graphMetricEnum.noise,
        graphMetricEnum.nox,
        graphMetricEnum.relativeHumidity,
        graphMetricEnum.temperature,
        graphMetricEnum.voc,
      ];
    case 'ProthrombinTime':
      return [graphMetricEnum.inr, graphMetricEnum.pt];
    default:
      return [];
  }
}
