import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { SelectField } from '../containers/Form';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import userActions from '../actions/user';
import { actions } from '../pages/SuperUser/Patients/redux/actions';
import { REASSIGN_CARE_PROVIDER_ERROR } from '../pages/SuperUser/Patients/redux/constants';

function ReassignCareProviderModal(props) {
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    orgId: props.data?.organizationId,
    care_providers: true,
  });

  const [careProviders, setCareProviders] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState(null);
  const [selectedCareProvider, setSelectedCareProvider] = React.useState(null);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);
  const [highlightInvalidFields, setHighlightInvalidFields] = React.useState(false);

  useEffect(() => {
    props.getUsers(pageRequest.current).then(response => {
      if (response && response.response.data) {
        setCareProviders(response.response.data);
        const careProviderOrgs = _.uniq(response.response.data.map(c => c.organization));
        if (careProviderOrgs.length === 1 && !selectedOrg) {
          setSelectedOrg(careProviderOrgs[0]);
        }
      }
    });
  }, []);

  const onSubmit = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const data = {
      old_care_provider: props.data?.user?.masked_id,
      organization_id: props.data?.organizationId,
      new_care_provider: selectedCareProvider === 'none' ? null : selectedCareProvider,
    };
    
    props.onSubmit(data).then(response => {
      if (response) {
        if (response.type === REASSIGN_CARE_PROVIDER_ERROR) {
          const error = response.response?.data?.standard_error?.message;
          props.showNotification(error, 5000, true);
        } else {
          props.showNotification(Strings.reassignCareProviderSuccess);
          props.closeModalWithNextAction();
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const careProviderOrgs = _.uniq(careProviders.map(c => c.organization));
  const pages = [
    {
      id: 'reassign-care-provider',
      title: Strings.reassignCareProvider,
      content: (
        <React.Fragment>
          {careProviderOrgs.length > 1 && (
            <SelectField
              name="organization"
              id="organization"
              label={Strings.organization}
              value={selectedOrg}
              onChange={o => setSelectedOrg(o.value)}
              data={careProviderOrgs.map(o => ({ value: o, label: o }))}
              isRequired
              highlightInvalid={highlightInvalidFields}
            />
          )}
          <SelectField
            name="careProvider"
            id="careProvider"
            label={Strings.capPatient.careProvider}
            value={selectedCareProvider}
            onChange={o => setSelectedCareProvider(o.value)}
            data={
              [{ value: 'none', label: Strings.none}]
                .concat(careProviders
                  .filter(c => c.id !== props.data?.user?.id)
                  .filter(c => selectedOrg ? c.organization === selectedOrg : c.value === 'none')
                  .map(c => ({ ...c, value: c.masked_id, label: `${c.first_name} ${c.last_name}`}))
                )
            }
            isRequired
            highlightInvalid={highlightInvalidFields}
          />
        </React.Fragment>
      ),
      emptyFieldsCount: selectedCareProvider ? 0 : 1,
      canGoNext: selectedCareProvider && !saveBtnClicked,
    },
  ];

  return (
    <Wizard
      name="reassign-care-provider"
      pages={pages}
      onNextButtonHover={e => setHighlightInvalidFields(e)}
      onSubmit={onSubmit}
      showPagesFilter={false}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: pageRequest => dispatch(userActions.list(pageRequest)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  onSubmit: data => dispatch(actions.reassignCareProvider(data)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('reassign-care-provider'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess();
  },
});

export default connect(null, mapDispatchToProps)(ReassignCareProviderModal);
