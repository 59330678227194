import React from 'react';
import PropTypes from 'prop-types';

import {Input, PhoneInputField, TextArea } from '../../containers/Form';
import Strings from '../../Strings';


function ContactInformationPage(props) {
  const { patient, highlightInvalidFields, validationErrors, setPatient, fields } = props;

  const onChange = (name, value) => {
    setPatient(p => ({
      ...p,
      [name]: value,
    }));
  };

  const contactInformationPage = (
    <React.Fragment>
      <PhoneInputField
        align="left"
        name="textPhoneNo"
        id="textPhoneNo"
        value={patient.textPhoneNo}
        onChange={e => onChange('textPhoneNo', e.target.value)}
        label={Strings.capPatient.mobileNumber}
        isRequired={fields.textPhoneNo.required}
        disabled={fields.textPhoneNo.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'textPhoneNo')?.errors || []}
        rowClassName="double-width-left"
      />
      <TextArea
        name="homeAddress"
        id="homeAddress"
        rows={4}
        value={patient.homeAddress || ''}
        label={Strings.capPatient.address}
        onChange={e => onChange("homeAddress", e.target.value)}
        isRequired={fields.homeAddress.required}
        disabled={fields.homeAddress.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'homeAddress')?.errors || []}
        rowClassName="double-width-right gridspan-3"
      />
      <Input
        style={{ gridColumn: 1 }}
        name="email"
        id="email"
        label={Strings.email}
        type="text"
        value={patient.email || ''}
        onChange={e => onChange("email", e.target.value)}
        isRequired={fields.email.required}
        disabled={fields.email.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
        rowClassName="double-width-left"
      />
    </React.Fragment>
  );

  return contactInformationPage;
}

export default ContactInformationPage;
