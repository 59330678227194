import moment from "moment-timezone";
import { DATE_FORMAT_YEAR_MONTH_DAY, RPM_PATIENT_STATUS } from "../constants";

const fieldTypes = {
  simpleField: 'simpleField',
  combinedField: 'combinedField',
  simpleArray: 'simpleArray',
  complexArray: 'complexArray',
};

export const patientFieldsEnum = {
  firstName: {
    fieldId: 'firstName',
    fieldType: fieldTypes.simpleField,
    required: () => { return true },
    toDTO: val => val?.trim() || val,
  },
  lastName: {
    fieldId: 'lastName',
    fieldType: fieldTypes.simpleField,
    required: () => { return true },
    toDTO: val => val?.trim() || val,
  },
  mrn: {
    fieldId: 'mrn',
    fieldType: fieldTypes.simpleField,
    required: () => { return false },
    toDTO: val => val?.trim() || val,
  },
  dateOfBirth: {
    fieldId: 'dateOfBirth',
    fieldType: fieldTypes.simpleField,
    required: () => { return true },
    toDTO: val => val ? moment(val).format(DATE_FORMAT_YEAR_MONTH_DAY) : null,
  },
  textPhoneNo: {
    fieldId: 'textPhoneNo',
    fieldType: fieldTypes.simpleField,
    required: (patient) => patient.consentForProvider === 'set',
    toDTO: val => val?.trim() || val,
  },
  email: {
    fieldId: 'email',
    fieldType: fieldTypes.simpleField,
    required: (patient) => { return patient?.last_login },
    toDTO: val => val?.trim() || val,
  },
  homeAddress: {
    fieldId: 'homeAddress',
    fieldType: fieldTypes.simpleField,
    required: () => { return false },
    toDTO: val => val?.trim() || val,
  },
  facilityId : {
    fieldId: 'facilityId',
    fieldType: fieldTypes.simpleField,
    required: () => { return false },
  },

  insurance: {
    fieldId: 'insurance',
    fieldType: fieldTypes.complexArray,
    required: () => { return false },
  },

  centerDetails: {
    fieldId: 'centerDetails',
    fieldType: fieldTypes.complexArray,
    required: () => { return false },
  },

  conditions: {
    fieldId: 'conditions',
    fieldType: fieldTypes.simpleArray,
    required: () => { return false },
  },

  transplantDetails: {
    fieldId: 'transplantDetails',
    fieldType: fieldTypes.complexArray,
    toDTO: val => val.map(t => ({
      ... t,
      transplantDate: t.transplantDate ? moment(t.transplantDate).format(DATE_FORMAT_YEAR_MONTH_DAY) : null,
    })
    ),
    required: () => { return false },
  },

  timezone: { fieldId: 'timezone', fieldType: fieldTypes.simpleField, required: () => { return false }},
  gender: { fieldId: 'gender', fieldType: fieldTypes.simpleField, required: () => { return true } },
  language: {
    fieldId: 'language',
    fieldType: fieldTypes.simpleField,
    required: () => { return false }
  },
  medications: {
    fieldId: 'medications',
    fieldType: fieldTypes.complexArray,
    required: () => { return false },
    toDTO: (val, patient) => val?.filter(m => m.trim().length > 0).map(m => ({
      name: m.trim(),
    })).concat(patient?.medicationsWithExtraData || []),
  },
  consentForProvider: {
    fieldId: 'consentForProvider',
    required: (_, stage) => stage && (stage !== RPM_PATIENT_STATUS.onboarded),
  },
  initiateEnrollmentByProviderAt: {
    fieldId: 'initiateEnrollmentByProviderAt',
    required: (p) => {return p?.televisitChecked},
    readOnly: (p, stage) => { return p.consentForProvider !== 'set' || stage !== RPM_PATIENT_STATUS.verified },
    toDTO: val => val ? moment(val).format() : null,
  }
};

export const patientToDTO = patient => {
  return Object.values(patientFieldsEnum).reduce((acc, field) => {
    const value = patient[field.fieldId];
    if (value) {
      acc[field.fieldId] = field.toDTO ? field.toDTO(value, patient) : value;
    }
    return acc;
  }, {});
};

export const checkIfPatientWasModified = (patient, originalPatient) => {
  return Object.values(patientFieldsEnum).some(field => {
    const value = field.toDTO ? field.toDTO(patient[field.fieldId]) : patient[field.fieldId];
    const originalValue = field.toDTO ? field.toDTO(originalPatient[field.fieldId]) : originalPatient[field.fieldId];
    return !_.isEqual(value, originalValue);
  });
}

export const fixNewConditions = (patient, newConditions) => {
  if (!patient?.conditionsObjects || patient.conditionsObjects.length === 0) {
    return patient;
  }

  const patientConditionIds = patient.conditionsObjects ? patient.conditionsObjects.map(c => {
    const id = c.id || newConditions.find(nc => nc.code === c.code)?.id;
    return id}) : [];
  
  return {
    ...patient,
    conditions: patientConditionIds,
  }
}

