import React from 'react';

import {
  Input,
} from '../../containers/Form';
import Strings from '../../Strings';
import { addListItem, editListItem, removeListItem } from './helpers';

function InsurancePage(props) {
  const { patient, highlightInvalidFields, fields, validationErrors, setPatient } = props;

  const insurancePage = (
    <React.Fragment>
      {patient.insurance &&
        patient.insurance.length > 0 &&
        patient.insurance.map((ins, i) => (
          <React.Fragment key={`ins_${i}`}>
            <Input
              name="insuranceName"
              id="insuranceName"
              label={Strings.capPatient.insuranceName}
              type="text"
              value={ins.insuranceName || ''}
              onChange={e => editListItem(e, 'insurance', i, setPatient)}
              isRequired={fields.insurance.required}
              disabled={fields.insurance.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'insuranceName')
                  ?.errors || []
              }
            />
            <Input
              name="policyId"
              id="policyId"
              label={Strings.capPatient.policyId}
              type="text"
              value={ins.policyId || ''}
              onChange={e => editListItem(e, 'insurance', i, setPatient)}
              isRequired={fields.insurance.required}
              disabled={fields.insurance.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'policyId')
                  ?.errors || []
              }
            />
            <Input
              name="groupNumber"
              id="groupNumber"
              label={Strings.capPatient.groupNumber}
              type="text"
              value={ins.groupNumber || ''}
              onChange={e => editListItem(e, 'insurance', i, setPatient)}
              isRequired={fields.insurance.required}
              disabled={fields.insurance.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'groupNumber')
                  ?.errors || []
              }
            />
            <div className="button-container ">
              <button
                className="minus-button high"
                onClick={() => removeListItem('insurance', i, setPatient)}
                disabled={patient.insurance.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('insurance', setPatient)}></button>
      </div>
    </React.Fragment>
  );

  return insurancePage;
}

export default InsurancePage;
