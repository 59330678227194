import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from '../../../components/Select';
import Strings from '../../../Strings';
import { actions } from '../Organization/redux/actions';
import './Configuration.scss';
import { fixPermission, permissionsToDTO, RecursiveForm } from './OrgPermissions';
import { UPDATE_ORG_ROLES_DETAIL_ERROR } from '../Organization/redux/constants';
import { notificationActions } from '../../../components/Notification/redux/actions';

function OrgRolePermissions(props) {
  const { roles, orgRoleConfig, orgConfig, isLoading } = props;

  const [data, setData] = React.useState();
  const [role, setRole] = React.useState('');
  const [sectionsExpanded, setSectionsExpanded] = React.useState({})

  const request = React.useRef({
    organizationId: decodeURIComponent(props.organizationId),
    roleUuid: role,
  });

  React.useEffect(() => {
    props.getOrgRole(request.current);
    props.getOrgConfig(request.current);
  }, []);

  React.useEffect(() => {
    if (orgRoleConfig && orgConfig) {
      const config = _.cloneDeep(orgConfig);
      fixPermission(config.permissions, false);
      _.merge(config.permissions, orgRoleConfig);
      fixPermission(config.permissions, true);
      Object.keys(config).forEach(key => {
        if (key !== 'permissions' && key !== 'two_fa_enabled') {
          delete config[key];
        }
      });
            
      setSectionsExpanded(Object.keys(orgConfig).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }
      , {}));
      setData(config);
    }
  }, [orgRoleConfig, orgConfig]);

  const onRoleChange = option => {
    setRole(option.value);
    request.current.roleUuid = option.value;
    props.getOrgRoleConfig(request.current);
  };

  const changeSectionExpansion = section => {
    setSectionsExpanded(s => ({
      ...s,
      [section]: !s[section],
    }));
  };

  const onSave = () => {
    const dataToSend = cloneDeep(data);
    permissionsToDTO(dataToSend.permissions);

    props.updateOrgRoleConfig(dataToSend, request.current).then(resp => {
      if (resp?.type === UPDATE_ORG_ROLES_DETAIL_ERROR) {
        props.showNotification(resp.response?.data?.error?.message, 5000, true);
      } else {
        props.showNotification(Strings.orgConfigUpdated);
      }
    });
  };

  return (
    isLoading ? <div className="spinner" /> :
    <React.Fragment>
      <div className="dd-container" style={{ margin: '20px 0' }}>
        {roles && (
          <div className="dd-ele">
            <div className="dd-title">{Strings.role}</div>
            <Select
              value={role}
              autoWidth
              onChange={onRoleChange}
              data={[{ value: '', label: Strings.role }].concat(
                Object.keys(roles).map(key => {
                  return { value: roles[key].role_uuid, label: `${roles[key].archetype} (${roles[key].title})` };
                }),
              )}
            />
          </div>
        )}
      </div>
      {role && (
        <div className="config-sections">
          {data && (
            <RecursiveForm
              path={[]}
              recursionLevel={0}
              data={data}
              setData={setData}
              onHeaderClick={changeSectionExpansion}
              sectionsExpanded={sectionsExpanded}
            />
          )}
          <button className="brand-blue" key="submit" onClick={onSave}>
            {Strings.submit}
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

OrgRolePermissions.propTypes = {
  getOrgConfig: PropTypes.func,
  getOrgRole: PropTypes.func,
  getOrgRoleConfig: PropTypes.func,
  isLoading: PropTypes.any,
  orgConfig: PropTypes.shape({
    permissions: PropTypes.any,
  }),
  orgRoleConfig: PropTypes.any,
  organizationId: PropTypes.any,
  roles: PropTypes.any,
  updateOrgRoleConfig: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    roles: organizations?.roles,
    orgRoleConfig: organizations?.orgRoleConfig,
    isLoading: organizations?.isLoading,
    orgConfig: organizations?.orgConfig,
  };
};

const mapDispatchToProps = dispatch => ({
  getOrgRole: pageRequest => dispatch(actions.getOrgRole(pageRequest)),
  getOrgRoleConfig: pageRequest => dispatch(actions.getOrgRoleConfig(pageRequest)),
  updateOrgRoleConfig: (data, pageRequest) => dispatch(actions.updateOrgRoleConfig(data, pageRequest)),
  getOrgConfig: pageRequest => dispatch(actions.getOrgConfig(pageRequest)),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgRolePermissions);
