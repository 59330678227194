import React from "react";
import moment from 'moment-timezone';

import Strings from "../../Strings";
import { DateInput, SelectField, TextArea, TimeInput } from '../../containers/Form';
import { calculateDateInNewTimezone } from "../EditRpmSchedule/EditRpmScheduleModal";

export default function DateTimeField(props) {
  const { value} = props;
  const [date, setDate] = React.useState(value);
  const [time, setTime] = React.useState(value ? moment.tz(value, props.timezone).format('hh:mm') : null);
  const [timeAmPm, setTimeAmPm] = React.useState(value ? moment.tz(value, props.timezone).format('A') : null);

  const onChange = (date, time, timeAmPm) => {
    setDate(date);
    setTime(time);
    setTimeAmPm(timeAmPm);
    if (!props.onChange) {
      return;
    }
    if (date && time && timeAmPm) {
      const datetime = new Date(date);
      const [hours, minutes] = time.split(':').map(Number);
      datetime.setHours(timeAmPm === 'AM' ? hours : hours + 12, minutes);
      const timeInTz = calculateDateInNewTimezone(datetime, moment.tz.guess(), props.timezone);
      props.onChange(timeInTz);
    } else {
      props.onChange(null);
    }
  };
  return (
    <React.Fragment>
      <DateInput
        placeholder="MM / DD / YYYY hh:mm a"
        value={date || ''}
        format="MM / dd / yyyy"
        onChange={d => onChange(d, time, timeAmPm)}
        timezone={props.timezone}
        {...props.dateInputProps}
      />
      <div className="horizontal-flex double-width-left">
        <div className="horizontal-flex">
          <TimeInput
            format="hh:mm"
            value={time}
            onChange={t => onChange(date, t, timeAmPm)}
            {...props.timeInputProps}
          />
          <SelectField
            value={timeAmPm}
            onChange={option => onChange(date, time, option.value)}
            data={[
              { value: 'AM', label: Strings.patient_medications.am },
              { value: 'PM', label: Strings.patient_medications.pm },
            ]}
            {...props.timeAmPmSelectProps}
          />
        </div>
      </div>
    </React.Fragment>
  );
}