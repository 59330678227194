import React from 'react';

import { DateInput, Input, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import { GetTimezoneTextMapping } from '../../constants';

function PatientDetailsPage(props) {
  const { patient, highlightInvalidFields, validationErrors, setPatient, fields } = props;
  const timezones = GetTimezoneTextMapping();

  const onChange = event => {
    const { name, value } = event.target;
    setPatient(p => ({
      ...p,
      [name]: value,
    }));
  };

  const patientDetailsPage = (
    <React.Fragment>
      <Input
        name="firstName"
        id="firstName"
        label={Strings.firstName}
        type="text"
        value={patient.firstName || ''}
        onChange={onChange}
        isRequired={fields.firstName.required}
        disabled={fields.firstName.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
      />
      <Input
        name="lastName"
        id="lastName"
        label={Strings.lastName}
        type="text"
        value={patient.lastName || ''}
        onChange={onChange}
        isRequired={fields.lastName.required}
        disabled={fields.lastName.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
      />
      <Input
        name="mrn"
        id="mrn"
        label={Strings.capPatient.patientId}
        type="text"
        value={patient.mrn || ''}
        onChange={onChange}
        isRequired={fields.mrn.required}
        disabled={fields.mrn.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'mrn')?.errors || []}
      />
      <DateInput
        name="dateOfBirth"
        id="dateOfBirth"
        label={Strings.dateOfBirth}
        value={patient?.dateOfBirth || ''}
        format="MM / dd / yyyy"
        maxDate={new Date()}
        onChange={date => setPatient(p => ({ ...p, dateOfBirth: date }))}
        isRequired={fields.dateOfBirth.required}
        disabled={fields.dateOfBirth.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'dateOfBirth')?.errors || []}
      />
      <SelectField
        name="gender"
        id="gender"
        value={patient.gender}
        label={Strings.gender}
        placeholder={Strings.SelectField}
        isRequired={fields.gender.required}
        disabled={fields.gender.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'gender')?.errors || []}
        onChange={option => setPatient(p => ({...p, gender: option.value}))}
        data={Object.values(Strings.genders).map(v => ({ value: v, label: v }))}
      />
      <SelectField
        name="language"
        id="language"
        value={patient.language}
        label={Strings.language}
        placeholder={Strings.SelectField}
        isRequired={fields.language.required}
        disabled={fields.language.readOnly}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'language')?.errors || []}
        onChange={option => setPatient(p => ({...p, language: option.value}))}
        data={Object.entries(Strings.languagesEnum).map(([k, v]) => ({ value: k, label: v }))}
      />
      <SelectField
        name="timezone"
        id="timezone"
        value={patient.timezone}
        label={Strings.timezone}
        placeholder={Strings.SelectField}
        onChange={option => setPatient(p => ({...p, timezone: option.value}))}
        data={[{ value: '', label: Strings.selectTimezone }].concat(
          Object.keys(timezones).map(key => {
            return { value: key, label: timezones[key] };
          }),
        )}
        isRequired={fields.timezone.required}
        disabled={fields.timezone.readOnly}
      />
    </React.Fragment>
  );

  return patientDetailsPage;
}

export default PatientDetailsPage;
