import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTimePicker from 'react-time-picker';

function TimePicker(props) {
  const { format, value, onChange, id, className } = props;
  const [minutes, setMinutes] = useState(value?.split(':')?.[1]);
  const [hours, setHours] = useState(value?.split(':')?.[0]);

  const minutesChanged = e => {
    setMinutes(e.target.value);
  };

  const hoursChanged = e => {
    setHours(e.target.value);
  };

  useEffect(() => {
    if (((hours && !minutes) || (!hours && minutes) || minutes > 59) && props.onInvalidValue) props.onInvalidValue();
  }, [minutes, hours]);

  useEffect(() => {
    document
      .querySelector(`#${id}`)
      ?.querySelector('input[name="minute"]')
      ?.addEventListener('input', minutesChanged);
    return () => {
      document
        .querySelector(`#${id}`)
        ?.querySelector('input[name="minute"]')
        ?.removeEventListener('input', minutesChanged);
    };
  }, []);

  useEffect(() => {
    document
      .querySelector(`#${id}`)
      ?.querySelector('input[name="hour12"]')
      ?.addEventListener('input', hoursChanged);
    return () => {
      document
        .querySelector(`#${id}`)
        ?.querySelector('input[name="hour12"]')
        ?.removeEventListener('input', hoursChanged);
    };
  }, []);

  const onTimeChange = time => {
    if (minutes && hours) {
      onChange(time);
    }
    if (!minutes && !hours) {
      onChange(null);
    }
  };

  return (
    <div id={id}>
      <ReactTimePicker
        value={value}
        format={format}
        onChange={onTimeChange}
        disableCalendar
        disableClock
        clearIcon={null}
        hourAriaLabel="Hour (in 12-hour time)"
        minuteAriaLabel="Minute"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        className={className}
        disabled={props.disabled}
      />
    </div>
  );
}

TimePicker.propTypes = {
  onChange: PropTypes.func,
  onInvalidValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
  timezone: PropTypes.string,
  id: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default TimePicker;
