import React from 'react';
import { connect } from 'react-redux';

import { SelectField, TextArea } from '../../containers/Form';
import Strings from '../../Strings';
import { Timezones } from '../../constants';
import DateTimeField from './DateTimeField';

function RegistrationPage(props) {
  const { patient, registrationNote, highlightInvalidFields, validationErrors, setPatient, setRegistrationNote, fields } = props;

  const patientOriginalPhoneNumber = patient.textPhoneNo;
  const patientTimezoneInfo = patient?.timezone !== props.timezone || patient?.timezone !== props.orgTimezone ? ` (${Strings.formatString(Strings.enrollmentDateTimezoneTooltip, Timezones[patient?.timezone], Strings.patient)})` : '';

  const onPatientChange = (name, value) => {
    setPatient(p => ({
      ...p,
      [name]: value,
    }));
  };
 
  const registerSection = (
    <React.Fragment>
      <SelectField
        name="consentForProvider"
        id="consentForProvider"
        label={Strings.select}
        value={patient?.consentForProvider}
        onChange={option => {
          onPatientChange('consentForProvider', option.value);
          onPatientChange('outreachCompleted', option.value);
        }}
        placeholder={Strings.noOptionSelected}
        data={Object.entries(Strings.outreachStatusEnum).map(([k, v]) => ({ value: k, label: v }))}
        isRequired={fields.consentForProvider.required}
        disabled={fields.consentForProvider.readOnly}
        errorsForTooltip={validationErrors.find(v => v.property === 'consentForProvider')?.errors || []}
        highlightInvalid={highlightInvalidFields}
        rowClassName="double-width-left"
      />
      <div className="double-width-right gridspan-4">
        <TextArea
          name="notes_registration"
          id="notes_registration"
          rows={4}
          value={registrationNote}
          label={Strings.pharmacyNotesToProvider}
          onChange={e => setRegistrationNote(e.target.value)}
        />
      </div>
      <SelectField
        name="televisitChecked"
        id="televisitChecked"
        label={Strings.capPatient.scheduleTelevisit}
        data={[
          { value: true, label: Strings.enabled },
          { value: false, label: Strings.disabled },
        ]}
        value={patient.televisitChecked}
        onChange={option => onPatientChange('televisitChecked', option.value)}
        isRequired={fields.initiateEnrollmentByProviderAt.required}
        disabled={fields.initiateEnrollmentByProviderAt.readOnly}
        errorsForTooltip={validationErrors.find(v => v.property === 'televisitChecked')?.errors || []}
        rowClassName="double-width-left"
      />
      {patient.televisitChecked && (
        <DateTimeField
          value={patient.initiateEnrollmentByProviderAt}
          onChange={date => onPatientChange('initiateEnrollmentByProviderAt', date)}
          timezone={patient?.timezone}
          dateInputProps={{
            name: 'televisit_date',
            id: 'televisit_date',
            label: `${Strings.scheduledDate}${patientTimezoneInfo}`,
            highlightInvalid: highlightInvalidFields,
            isRequired: fields.initiateEnrollmentByProviderAt.required,
            disabled: fields.initiateEnrollmentByProviderAt.readOnly,
            errorsForTooltip: validationErrors.find(v => v.property === 'initiateEnrollmentByProviderAt')?.errors || [],
            rowClassName: 'double-width-left',
          }}
          timeInputProps={{
            id: 'televisit_time',
            name: 'televisit_time',
            label: Strings.time,
            highlightInvalid: highlightInvalidFields,
            isRequired: fields.initiateEnrollmentByProviderAt.required,
            disabled: fields.initiateEnrollmentByProviderAt.readOnly,
            errorsForTooltip: validationErrors.find(v => v.property === 'initiateEnrollmentByProviderAt')?.errors || [],
          }}
          timeAmPmSelectProps={{
            name: 'televisit_time_am_pm',
            id: 'televisit_time_am_pm',
            label: ' ',
            highlightInvalid: highlightInvalidFields,
            isRequired: fields.initiateEnrollmentByProviderAt.required,
            disabled: fields.initiateEnrollmentByProviderAt.readOnly,
            errorsForTooltip: validationErrors.find(v => v.property === 'initiateEnrollmentByProviderAt')?.errors || [],
          }}
        />
      )}
    </React.Fragment>
  );

  return registerSection;
}
const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    orgTimezone: state.auth?.ui_config?.timezone,
  };
};

export default connect(mapStateToProps, null)(RegistrationPage);
