import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';

import { sessionLogout, userProfile } from '../../actions/auth';
import { closeModal, openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { trialsAction } from '../../actions/trials';
import LoadingRenderer from '../../components/LoadingRenderer';
import Notification from '../../components/Notification/view/Notification';
import { IDLE_TIME_ON_CONFIRM_MSG, IS_EXPIRTED_SESSION_INTEVAL } from '../../constants';
import AddEditHardwareId from '../../modals/AddEditHardwareId/AddEditHardwareId';
import AddEditProvisioning from '../../modals/AddEditProvisioning/AddEditProvisioning';
import AddESIPatientModal from '../../modals/AddESIPatientModal/AddESIPatientModal';
import AddNickNameModal from '../../modals/AddNickNameModal';
import AddOrganizationModal from '../../modals/AddOrganizationModal';
import BulkAddPatientsModal from '../../modals/BulkAddPatients/BulkAddPatientsModal';
import AddScheduleToTheCapModal from '../../modals/AddScheduleToTheCap/AddScheduleToTheCapModal';
import AttachCapToOrgnization from '../../modals/AttachCapToOrgnization/AttachCapToOrgnization';
import AttachCapToSchedule from '../../modals/AttachCapToSchedule/AttachCapToSchedule';
import ConfirmationModal from '../../modals/ConfirmationModal';
import ConfirmYouThere, { MODAL_CONFIRM_YOU_THERE } from '../../modals/ConfirmYouThere';
import DownloadHistoryModal from '../../modals/DownloadHistoryModal/DownloadHistoryModal';
import DownloadMedicationModal from '../../modals/DownloadMedicationModal/DownloadMedicationModal';
import EditAdminModal from '../../modals/EditAdminModal';
import EditCaregiverModalOld from '../../modals/EditCaregiverModal';
import EditOrganization from '../../modals/EditOrganization';
import EditPatientModal from '../../modals/EditPatientModal';
import EditPatientScheduleModal from '../../modals/EditPatientScheduleModal/EditPatientScheduleModal';
import EditProfileModal from '../../modals/EditProfileModal';
import EditScheduleModal from '../../modals/EditScheduleModal';
import EditStockModal from '../../modals/EditStockModal/EditStockModal';
import EditStudyModal from '../../modals/EditStudyModal';
import EditUserProfileModal from '../../modals/EditUserProfileModal';
import EmptyModal from '../../modals/EmptyModal/EmptyModal';
import ESIPatientEditAddressModal from '../../modals/ESIPatientEditAddressModal/ESIPatientEditAddressModal';
import ESIPatientEditDetailsModal from '../../modals/ESIPatientEditDetailsModal/ESIPatientEditDetailsModal';
import TimezoneChangeModal from '../../modals/TimezoneChangeModal/TimezoneChangeModal';
import ESIPatientUpdateHardwareCapIdModal from '../../modals/ESIPatientUpdateHardwareCapIdModal/ESIPatientUpdateHardwareCapIdModal';
import InvitePatientModal from '../../modals/InvitePatientModal';
import NewConfirmationModal from '../../modals/NewConfirmationModal/NewConfirmationModal';
import NewTrialPatientModal from '../../modals/NewTrialPatientModal';
import OnBoardingTutorialModal from '../../modals/OnBoardingTutorialModal/OnBoardingTutorialModal';
import OutboundAPIModal from '../../modals/OutboundAPIModal';
import ScheduleTimePickerModal from '../../modals/ScheduleTimePickerModal';
import TechnicalSupportModal from '../../modals/TechicalSupport/TechicalSupport';
import UpdateTask from '../../modals/UpdateTask/UpdateTask';
import { actions } from '../../pages/ESI/Support/redux/actions';
import Strings from '../../Strings';
import { authUnset, setIdleTime } from '../../utils';
import { setTheme, setThemeFromLS } from '../../utils/colorTheme';
import { isEsi } from '../../utils/portalType';
import {
  getRoleFromSessionStorage,
  isDoctor,
  isESIPatient,
  isPatient,
  isSuperCapAdmin,
  PATIENT,
} from '../../utils/userRoles';
import { getPermissions } from '../../utils/userPermissions';
import ModalMaster from '../ModalMaster';
import { AppHeader } from './components/AppHeader';
import { Sidebar } from './components/Sidebar';
import SendSecurityCodeModal from '../../modals/SendSecurityCodeModal';
import DateRangeSelectionModal from '../../modals/DateRangeSelectionModal';
import DateRangeSelectionForEventsReportsModal from '../../modals/DateRangeSelectionForEventsReportsModal';
import { getLanguage } from '../../utils/language';
import ActivateYourDeviceModal from '../../modals/ActivateYourDeviceModal/ActivateYourDeviceModal';
import { setBrand, setBrandFromLS } from '../../utils/brand';
import CreateKitAndAddDevices from '../../modals/CreateKitAndAddDevices/CreateKitAndAddDevices';
import AttachKitModal from '../../modals/AttachKit/AttachKitModal';
import EditCohortModal from '../../modals/EditCohortModal/EditCohortModal';
import { getDefaultPageForUser } from '../../utils/defaultPages';
import EnrollPatientModal from '../../modals/EnrollPatientModal/EnrollPatientModal';
import EditConditionsModal from '../../modals/EditConditionsModal/EditConditionsModal';
import EditFacilitiesModal from '../../modals/EditFacilitiesModal/EditFacilitiesModal';
import EditRpmPatientModal from '../../modals/EditRpmPatient/EditRpmPatientModal';
import EditRpmScheduleModal from '../../modals/EditRpmSchedule/EditRpmScheduleModal';
import OnboardingModal from '../../modals/SummaryModals/OnboardingModal';
import SendMessageModal from '../../modals/SendMessageModal/SendMessageModal';
import AddEditNoteModal from '../../modals/SendMessageModal/AddEditNoteModal';
import EditPrefabReplyModal from '../../modals/EditPrefabReplyModal';
import SetupRpmModal from '../../modals/SummaryModals/SetupRpmModal';
import EditCaregiversModal from '../../modals/EditCaregiversModal';
import EulaModal from '../../modals/EulaModal/EulaModal';
import TimerModal from '../../modals/TimerModal/TimerModal';
import ExternalApiModal from '../../modals/ExternalApiModal';
import { isBrowser, isMobile } from 'react-device-detect';
import { MobileHeader } from './components/AppHeader/MobileHeader';
import EditUserModal from '../../modals/EditUserModal';
import WidgetConfigurationModal from '../../modals/WidgetConfigurationModal/WidgetConfigurationModal';
import DashboardConfigurationModal from '../../modals/WidgetConfigurationModal/DashboardConfigurationModal';
import ChangeStateModal from '../../modals/ChangeStateModal';
import StartEnrollmentAndSchedulesModal from '../../modals/StartEnrollmentAndSchedulesModal';
import ResetEnrollmentAndUnattachDevicesModal from '../../modals/ResetEnrollmentAndUnattachDevicesModal';
import OnboardPatientWizard from '../../modals/OnboardPatientWizard/OnboardPatientWizard';
import ReassignCareProviderModal from '../../modals/ReassignCareProviderModal';

class App extends Component {
  static propTypes = {
    onLeftButtonClick: PropTypes.func,
    onLogoClick: PropTypes.func,
    leftMenuVisible: PropTypes.bool,
    profile: PropTypes.object,
    children: PropTypes.any,
    showHomePage: PropTypes.func,
    isMainPage: PropTypes.bool,
    leftInteractionEnabled: PropTypes.bool,
    visible: PropTypes.bool,
    location: PropTypes.object,
    onChange: PropTypes.func,
    onLogOut: PropTypes.func,
  };
  keyPressTime;
  state = { leftMenuVisible: true };
  isTimerCleared = true;

  constructor(props) {
    super(props);
    this.state = { leftMenuVisible: isBrowser };
  }

  componentDidMount() {
    let resizeTimer;
    window.addEventListener('resize', () => {
      document.body.classList.add('resize-animation-stopper');
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
      }, 400);
    });

    // eslint-disable-next-line no-console
    if (!this.props.authenticated) {
      this.props.onNotAuthenticated();
      return;
    }

    if (this.props.ui_config?.theme) {
      setTheme(this.props.ui_config?.theme);
    } else {
      setThemeFromLS();
    }
    if (this.props.ui_config?.brand) {
      setBrand(this.props.ui_config?.brand);
    } else {
      setBrandFromLS();
    }

    if (!this.props.profile) {
      this.props.loadProfile().then(r => {
        const languageCode = getLanguage();
        Strings.setLanguage(languageCode);

        if (isESIPatient()) setTheme('white');

        const config = r.response?.config;
        if (isESIPatient()) {
          if (!config.esi_is_cap_id_set && !authConfig[CAP_SET_BY_SMS]) {
            this.props.onLogOut();
          }
        }

        // this.props.getConfig();
        if (this.props.authenticated && r.error && r.response) {
          this.props.onLogOut();
          return;
        }
        if (this.props.location.pathname === '/') {
          this.showHomePage();
        }
      });
      return;
    }
    this.setSessionInterval();
  }

  componentWillUnmount() {
    clearTimeout(this.keyPressTime);
    clearTimeout(this.logoutSession);
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (newProps.eula !== this.props.eula && newProps.eula?.has_to_be_accepted) {
      this.props.openEula(newProps.eula);
    }

    let isConfirmPopupOpen = false;
    if (!newProps.authenticated || !this.props.authenticated) {
      this.props.onNotAuthenticated();
      return;
    }
    if (Object.keys(newProps.modalsState).length) {
      const activePopupName = _.findKey(newProps.modalsState, d => d.open);
      if (activePopupName === MODAL_CONFIRM_YOU_THERE) {
        if (newProps.modalsState[MODAL_CONFIRM_YOU_THERE].open) {
          isConfirmPopupOpen = true;
        }
      }
    }

    if (!isConfirmPopupOpen) {
      setIdleTime();
      if (this.isTimerCleared) {
        this.setSessionInterval();
        this.isTimerCleared = false;
      }
    }

    // This is not used anymore
    if (!newProps.profile && !newProps.profileRequested) {
      this.props.loadProfile();
    }
  }

  setSessionInterval() {
    clearTimeout(this.keyPressTime);
    this.keyPressTime = setInterval(() => {
      const activePopupName = _.findKey(this.props.modalsState, d => d.open);
      const idleSessionTime = sessionStorage.getItem('interval');
      if (idleSessionTime) {
        if (idleSessionTime < new Date().getTime()) {
          if (activePopupName) {
            this.props.closeIdlePopup(activePopupName);
          }
          clearTimeout(this.keyPressTime);
          this.isTimerCleared = true;
          this.sessionTimeout();
          this.props.confirmYouThere();
        }
      }
    }, IS_EXPIRTED_SESSION_INTEVAL);
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime = sessionStorage.getItem('interval');
      const currentTime = new Date().getTime();
      if (idleSessionTime < currentTime) {
        this.props.onLogOut();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, IDLE_TIME_ON_CONFIRM_MSG);
  }

  onLogin(response) {
    this.props.onUserLogin(response);
  }

  onPageLoad = () => {
    this.props.loadStudies();
  };

  onPageChange = path => {
    this.props.onNavigate(path);
  };

  leftSideMenuToggle = e => {
    this.setState({ leftMenuVisible: !this.state.leftMenuVisible });
    if (e) {
      e.stopPropagation();
    }
  };

  showHomePage = () => {
    const page = getDefaultPageForUser();
    this.props.onNavigate(page);
  };

  render() {
    if (!this.props.authenticated) {
      return <div />;
    }

    const { children, location, onLogOut, profile, origin, userId, role, notification } = this.props;

    if (typeof role === 'undefined') {
      return <LoadingRenderer loading />;
    }

    const { leftMenuVisible } = this.state;

    let userName = '';
    if (profile) {
      let email = profile.email;
      // We always show intercom for superadmin
      if (!email && isSuperCapAdmin()) email = '-';

      if (email) {
        // let name = `${profile.first_name} ${profile.last_name}`;
        // if (!profile.first_name) {
        //   name = profile.username;
        // }
        // if (support?.data?.flags?.intercom) {
        //   window.Intercom('boot', {
        //     app_id: APP_ID,
        //     name,
        //     email,
        //     created_at: new Date(),
        //     deviceType: 'web(ESI)',
        //   });
        // }
      }
      userName = profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username;
    }

    const isMainPage = isDoctor()
      ? location.pathname.startsWith('/shipper-dashboard')
      : location.pathname.startsWith('/activity');

    if (isEsi()) {
      const favicon = document.getElementById('favicon');
      favicon.href = '../.././faviconLidSync.ico';
    }

    return (
      <div className={`app-root ${isDoctor() ? 'doctor-portal' : 'patient-portal'}`}>
        <div className={`app-main ${isMobile ? 'mobile' : ''}`}>
          {isMobile ? (
            <React.Fragment>
              <MobileHeader
                onMenuClick={this.leftSideMenuToggle}
                onLogoClick={this.showHomePage}
                leftInteractionEnabled={origin !== 'fhir'}
                leftMenuVisible={leftMenuVisible}
              />
              <Sidebar
                leftMenuVisible={leftMenuVisible}
                location={location}
                onChange={path => {
                  this.leftSideMenuToggle();
                  this.onPageChange(path);
                }}
                onLogOut={onLogOut}
                patientId={userId}
              />
            </React.Fragment>
          ) : (
            <div className={`left-bar ${leftMenuVisible ? '' : 'collapsed'}`}>
              <AppHeader
                {...this.state}
                onLogoClick={this.showHomePage}
                onLeftButtonClick={this.leftSideMenuToggle}
                leftInteractionEnabled={origin !== 'fhir'}
                isMainPage={isMainPage}
                leftMenuVisible={leftMenuVisible}
              />
              {origin === 'fhir' ? null : (
                <Sidebar
                  leftMenuVisible={leftMenuVisible}
                  location={location}
                  onChange={this.onPageChange}
                  onLogOut={onLogOut}
                  patientId={userId}
                />
              )}
            </div>
          )}
          <div className="app-content-container">
            <div className={`app-content ${isMobile ? 'mobile' : ''}`}>{children}</div>
          </div>
        </div>
        {notification.message && <Notification {...notification} />}
        <ModalMaster>
          <EditStudyModal name="new-trial" />
          <ConfirmYouThere name={MODAL_CONFIRM_YOU_THERE} caption={Strings.sessionTimeout} />
          <AddNickNameModal name="add-nick-name" caption={Strings.addNickName} />
          <EditAdminModal name="new-admin" caption={Strings.addAdmin} />
          <EditOrganization name="edit-organization" caption={Strings.editOrganization} />
          <AddOrganizationModal name="add-organization" caption={Strings.addOrganization} />
          <OnBoardingTutorialModal name={OnBoardingTutorialModal.MODAL_NAME} caption={Strings.tutorial.dashTitle} />
          <EditPatientModal name="new-patient" caption={Strings.addPatients} />
          <AddESIPatientModal name="add-esi-patient" caption={Strings.addPatients} />
          <EditUserModal name="edit-user" caption={Strings.addAdmin} />
          <OutboundAPIModal name="organization-outbound-api" caption={Strings.addOuboundAPI} />
          <InvitePatientModal name="invite-patient" caption={Strings.addPatients} />
          <EditCaregiverModalOld name="add-caregiver" caption={Strings.addCaregiver} />
          <EditCaregiversModal name="edit-caregivers" />
          <EditProfileModal name="edit-profile" caption={Strings.editProfile} />
          <EditUserProfileModal name="edit-user-profile" caption={Strings.editProfile} />
          <ConfirmationModal name="confirmation-modal" />
          <NewTrialPatientModal name="new-trial-patient" caption={Strings.addPatient} />
          <EditScheduleModal name="edit-schedule" />
          <ScheduleTimePickerModal name="schedule-time-picker" />
          <AttachCapToOrgnization name="attach-cap-to-org" caption={Strings.attachCasenp} />
          <SendSecurityCodeModal name="send-security-code" caption={Strings.SendDeviceSecurityCode} />
          <DateRangeSelectionModal name="data-range-selection-modalion" caption="Custom Date Range" />
          <DateRangeSelectionForEventsReportsModal name="date-range-selection-for-events-reports-modal" />
          <AttachCapToSchedule name="attach-cap-to-schedule" />
          <AddEditProvisioning name="add-edit-provisioning" caption={Strings.provisioning} />
          <AddEditHardwareId name="add-edit-hardware-id" caption={Strings.AddEditHardware} />
          <UpdateTask name="update-task" caption={Strings.capPatient.updateTask} />
          <ESIPatientEditAddressModal name={ESIPatientEditAddressModal.MODAL_NAME} caption={Strings.editAddress} />
          <ESIPatientEditDetailsModal name={ESIPatientEditDetailsModal.MODAL_NAME} caption={Strings.editDetails} />
          <NewConfirmationModal name={NewConfirmationModal.MODAL_NAME} caption={Strings.areYouSure} />
          <TechnicalSupportModal name="technical-support-modal" caption={Strings.technicalSupport} />
          <EditPatientScheduleModal name="edit-patient-schedule" caption={Strings.editSchedule} />
          <AddScheduleToTheCapModal name="add-schedule-to-the-cap" caption={Strings.schedules.addSchedule} />
          <BulkAddPatientsModal name="bulk-add-patients" caption={Strings.addPatients} />
          <ESIPatientUpdateHardwareCapIdModal
            name={ESIPatientUpdateHardwareCapIdModal.MODAL_NAME}
            caption="Register your Smart Cap"
          />
          <ActivateYourDeviceModal name="activate-your-device-modal" />
          <EditStockModal name="edit-stock" caption={Strings.editStock} />
          <DownloadHistoryModal name="download-history" caption={Strings.downloadHistory} />
          <DownloadMedicationModal name="download-medications" caption={Strings.downloadAllMeds} />
          <EmptyModal name={EmptyModal.MODAL_NAME} />
          <TimezoneChangeModal name={TimezoneChangeModal.MODAL_NAME} />
          <CreateKitAndAddDevices name="create-kit-and-add-devices" caption={Strings.createKit} />
          <AttachKitModal name="attach-kit" caption={Strings.attachKit} />
          <EditCohortModal name="edit-cohort" />
          <EnrollPatientModal name="enroll-patient" />
          <EditConditionsModal name="edit-conditions" />
          <EditFacilitiesModal name="edit-facilities" />
          <EditRpmPatientModal name="edit-rpm-patient" />
          <EditRpmScheduleModal name="edit-rpm-schedule" />
          <OnboardingModal name="you-are-all-done" />
          <SendMessageModal name="send-message" noOverlay />
          <AddEditNoteModal name="add-note" noOverlay />
          <EditPrefabReplyModal name="edit-prefab-reply" />
          <SetupRpmModal name="done-rpm-setup" />
          <EulaModal name="eula" />
          <TimerModal name="timer" noOverlay />
          <ExternalApiModal name="external-api-modal" />
          <WidgetConfigurationModal name="widget-configuration" type="side-panel" />
          <DashboardConfigurationModal name="dashboard-configuration" type="side-panel" />
          <ChangeStateModal name="change-state" />
          <StartEnrollmentAndSchedulesModal name="start-enrollmend-and-schedules" />
          <ResetEnrollmentAndUnattachDevicesModal name="reset-enrollment-and-unattach-devices" />
          <OnboardPatientWizard name="onboard-patient" />
          <ReassignCareProviderModal name="reassign-care-provider" />
        </ModalMaster>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { support } = state;
  let tempUserId = '';
  if (state.auth.profile) {
    if (state.auth.profile.id) {
      tempUserId = state.auth.profile.id;
    }
  }
  return {
    support,
    role: state.auth.role,
    readOnlyMode: state.auth.read_only,
    authenticated: state.auth.authenticated,
    profile: state.auth.profile,
    userId: tempUserId,
    profileRequested: state.auth.profileRequested,
    path: '',
    trials: state.entities.trials.trial,
    patients: state.entities.patients.patient,
    origin: state.auth.origin,
    modalsState: state.modals || {},
    notification: state.notification.notification || {},
    ui_config: state.auth.ui_config,
    eula: state.auth.eula,
  };
};

const mapDispatchToProps = dispatch => ({
  onNotAuthenticated: () => {
    if (window.location.pathname && window.location.pathname !== '/') {
      localStorage.setItem('redirectUrl', encodeURIComponent(window.location.href));
    }
    dispatch(push('/login'));
  },
  onNavigate: path => dispatch(push(path)),
  onBack: () => dispatch(goBack()),
  confirmYouThere: () => dispatch(openModalAction(MODAL_CONFIRM_YOU_THERE)),
  closeIdlePopup: modalName => dispatch(closeModal(modalName)),
  onLogOut: () => {
    // try to logout to be sure that session is flushed
    dispatch(sessionLogout())
      // .then(() => dispatch(logout()))
      .then(() => {
        const isUserPatient = isPatient() || getRoleFromSessionStorage() === PATIENT;
        const isUserEsi = isEsi();
        const location = isUserPatient && isUserEsi ? '/login-esi-patient' : '/login';
        const userPermissions = getPermissions();
        authUnset();
        // window.Intercom('shutdown');
        window.location = location;
      });
  },
  loadProfile: () => dispatch(userProfile()),
  loadStudies: () => dispatch(trialsAction.actionList()),
  loadPatients: () => dispatch(patientAction.actionList()),
  getConfig: () => dispatch(actions.getConfig()),
  openEula: eula => dispatch(openModalAction('eula', eula)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
