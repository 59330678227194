import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { cloneDeep } from 'lodash';
import { closeModal } from 'actions/modal';

import { Input } from '../../containers/Form';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import {
  CREATE_FACILITY_ERROR,
  DELETE_FACILITY_ERROR,
  EDIT_FACILITY_ERROR,
} from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import Wizard from '../../containers/Modal/Wizard';
import './EditFacilitiesModal.scss';
import { notificationActions } from '../../components/Notification/redux/actions';

const EditFacilitiesModal = props => {
  const [facilities, setFacilities] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);

  const scrollableNodeRef = useRef();

  useEffect(() => {
    props.getfacilities();
  }, []);

  useEffect(() => {
    setFacilities(props.facilities?.concat([{ title: '' }]));
  }, [props.facilities]);

  const onTextChange = (event, id) => {
    if (event.target.value != ' ') {
      let newfacilities = cloneDeep(facilities);
      // if empty entry is edited, add new empty one
      if (id === newfacilities.length - 1 && !newfacilities.slice(-1).title) {
        newfacilities = newfacilities.concat([{ title: '' }]);
        scrollToMyRef();
      }
      newfacilities[id].title = event.target.value;
      setFacilities(newfacilities);
    }
  };

  const onDelete = id => {
    setFacilities(facilities.filter(c => c.id !== id));
  };

  const scrollToMyRef = () => {
    window.requestAnimationFrame(function() {
      const scroll = scrollableNodeRef.current.scrollHeight - scrollableNodeRef.current.clientHeight;
      scrollableNodeRef.current.scrollTo({top: scroll, behavior: 'smooth'});
    });
  };

  const hasDataChanged = () => {
    return !_.isEqual(props.facilities, facilities.slice(0, -1));
  };

  const onSubmit = async () => {
    if (saveClicked) return;
    setSaveClicked(true);
    let fail = false;

    const toDelete = props.facilities.filter(c1 => !facilities.some(c2 => c2.id === c1.id));

    await Promise.all(
      toDelete.map(async p => {
        const response = await props.deleteFacility(p.id);
        if (response && response.type === DELETE_FACILITY_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    const toAdd = facilities.filter(c => !c.id && c.title);
    await Promise.all(
      toAdd.map(async p => {
        const response = await props.createFacility({ title: p.title });
        if (response && response.type === CREATE_FACILITY_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    const toEdit = facilities.filter(c1 => props.facilities.some(c2 => c2.id === c1.id && c2.title !== c1.title));
    await Promise.all(
      toEdit.map(async p => {
        const response = await props.editFacility(p.id, { title: p.title });
        if (response && response.type === EDIT_FACILITY_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    if (fail) {
      return false;
    }

    props.closeModal('edit-facilities');
    if (props.data?.onSuccess) {
      props.data.onSuccess();
    }
    return true;
  };

  const pages = [
    {
      id: 'editFacilitiesPage',
      title: Strings.editFacilityList,
      content:
        facilities &&
        facilities.map((c, i) => (
          <div key={`facility_${i}`} className="form-row">
            <Input
              name={`facilityName_${i}`}
              id={`facility_${i}`}
              label=""
              placeholder={Strings.providerFacility}
              type="text"
              value={c.title}
              onChange={e => onTextChange(e, i)}
            />
            <div className="item">
              <button className="minus" onClick={() => onDelete(c.id)} />
            </div>
          </div>
        )),
      nextButton: { text: !hasDataChanged() && props.data?.workflow ? Strings.skip : Strings.save },
      emptyFieldsCount: facilities?.[0] ? 0 : 1,
      canGoNext: facilities?.[0]?.title && (hasDataChanged() || props.data?.workflow),
    },
  ];
  return <Wizard ref={scrollableNodeRef} name="edit-facilities" pages={pages} onSubmit={onSubmit} />;
};

EditFacilitiesModal.propTypes = {
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  getfacilities: PropTypes.func,
  facilities: PropTypes.array,
  deleteFacility: PropTypes.func,
  createFacility: PropTypes.func,
  editFacility: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-facilities')),
  closeModal: () => dispatch(closeModal('edit-facilities')),
  onSubmit: cohort =>
    dispatch(
      ownProps.data.cohort?.id ? ownProps.data.action(ownProps.data.cohort?.id, cohort) : ownProps.data.action(cohort),
    ).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        if (ownProps.data.nextAction) dispatch(ownProps.data.nextAction);
        if (ownProps.data.onSuccess) {
          ownProps.data.onSuccess();
        }
      }
      return response;
    }),
  getfacilities: () => dispatch(actions.getFacilities()),
  deleteFacility: id => dispatch(actions.deleteFacility(id)),
  createFacility: data => dispatch(actions.createFacility(data)),
  editFacility: (id, data) => dispatch(actions.editFacility(id, data)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

const mapStateToProps = state => {
  return {
    facilities: state.superUser.cohorts?.facilities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFacilitiesModal);
