import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { cloneDeep } from 'lodash';
import { closeModal } from 'actions/modal';

import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import {
  CREATE_CONDITION_ERROR,
  DELETE_CONDITION_ERROR,
  EDIT_CONDITION_ERROR,
} from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import './EditConditionsModal.scss';
import Wizard from '../../containers/Modal/Wizard';
import { AsyncSelectField } from '../../containers/Form';
import { notificationActions } from '../../components/Notification/redux/actions';

const EditConditionsModal = props => {
  const [conditions, setConditions] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);

  const scrollableNodeRef = useRef();

  useEffect(() => {
    props.getConditions();
  }, []);

  useEffect(() => {
    setConditions(
      props.conditions?.map(c => {
        return {
          ...c,
          label: c.title,
          value: c.id,
        };
      }),
    );
  }, [props.conditions]);

  const onConditionSelected = (option, id) => {
    const { code, title } = option;
    if (option) {
      let newConditions = cloneDeep(conditions);
      if (!id) {
        newConditions.push(option);
        scrollToMyRef();
      } else {
        newConditions[id].title = title;
        if (code) newConditions[id].code = code;
      }
      setConditions(newConditions);
    }
  };

  const onDelete = id => {
    setConditions(conditions.filter(c => c.id !== id));
  };

  const scrollToMyRef = () => {
    window.requestAnimationFrame(function() {
      const scroll = scrollableNodeRef.current.scrollHeight - scrollableNodeRef.current.clientHeight;
      scrollableNodeRef.current.scrollTo({top: scroll, behavior: 'smooth'});
    });
  };

  const hasDataChanged = () => {
    return !_.isEqual(
      props.conditions,
      conditions?.map(c => {
        const { value, label, ...condition } = c;
        return condition;
      }),
    );
  };

  const onSubmit = async () => {
    if (saveClicked) return;
    setSaveClicked(true);
    let fail = false;

    const toDelete = props.conditions.filter(c1 => !conditions.some(c2 => c2.id === c1.id));
    await Promise.all(
      toDelete.map(async p => {
        const response = await props.deleteCondition(p.id);
        if (response && response.type === DELETE_CONDITION_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    const toAdd = conditions.filter(c => !c.id && c.title);
    await Promise.all(
      toAdd.map(async p => {
        const request = { title: p.title };
        if (p.code) request.icd10cm = p.code;
        const response = await props.createCondition(request);
        if (response && response.type === CREATE_CONDITION_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    const toEdit = conditions.filter(c1 =>
      props.conditions.some(c2 => c2.id === c1.id && (c2.title !== c1.title || c2.icd10cm != c1.icd10cm)),
    );
    await Promise.all(
      toEdit.map(async p => {
        const request = { title: p.title };
        if (p.code) request.icd10cm = p.code;
        const response = await props.editCondition(p.id, request);
        if (response && response.type === EDIT_CONDITION_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveClicked(false);
          fail = true;
        }
      }),
    );

    if (fail) {
      return false;
    }

    props.closeModal('edit-conditions');
    if (props.data?.onSuccess) {
      props.data.onSuccess();
    }
    return true;
  };

  const loadOptions = _.debounce((value, callback) => {
    if (value?.length < 3) {
      return;
    }
    setTimeout(() => {
      props.getIdcConditions({ search: value }).then(resp => {
        callback(() => {
          if (!resp.response.data || resp.response.data.length === 0) {
            return [];
          }
          const idcConditions = resp.response.data.map(e => {
            return {
              ...e,
              value: e.code,
              label: e.title,
            };
          });
          return idcConditions.filter(d => !conditions.some(p => p.title === d.title));
        });
      });
    }, 1000);
  }, 1000);

  const pages = [
    {
      id: 'editConditionsPage',
      title: Strings.editConditionList,
      content: (
        <React.Fragment>
          {conditions &&
            conditions.map((c, i) => (
              <div key={`div_condition_${c.id}_${c.title}`} className="form-row">
                <AsyncSelectField
                  id={`condition_${c.id}_${c.title}`}
                  placeholder={Strings.patient_medications.enter3Chars}
                  loadOptions={loadOptions}
                  defaultOptions={conditions}
                  onChange={o => onConditionSelected(o, i)}
                  value={c.value ? c : undefined}
                  getLabel={o => `${o.condition_icd10cm ? `(${o.condition_icd10cm}) ` : ''}${o.label}`}
                />
                {c.title && (
                  <div className="item">
                    <button className="minus" onClick={() => onDelete(c.id)} />
                  </div>
                )}
              </div>
            ))}
          <div className="form-row">
            <AsyncSelectField
              key="new_condition"
              placeholder={Strings.patient_medications.enter3Chars}
              loadOptions={loadOptions}
              defaultOptions={conditions}
              onChange={o => onConditionSelected(o)}
              isRequired
              highlightInvalidFields={highlightInvalidFields}
              clearValueAfterSelection
            />
          </div>
        </React.Fragment>
      ),
      nextButton: { text: !hasDataChanged() && props.data?.workflow ? Strings.skip : Strings.save },
      emptyFieldsCount: conditions?.[0] ? 0 : 1,
      canGoNext: conditions?.[0] && (hasDataChanged() || props.data?.workflow),
    },
  ];

  return (
    <Wizard
      ref={scrollableNodeRef}
      name="edit-conditions"
      pages={pages}
      onSubmit={onSubmit}
      onNextButtonHover={e => setHighlightMissingFields(e)}
    />
  );
};

EditConditionsModal.propTypes = {
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  getConditions: PropTypes.func,
  conditions: PropTypes.array,
  deleteCondition: PropTypes.func,
  createCondition: PropTypes.func,
  editCondition: PropTypes.func,
  getIdcConditions: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-conditions')),
  closeModal: () => dispatch(closeModal('edit-conditions')),
  getConditions: () => dispatch(actions.getConditions()),
  deleteCondition: id => dispatch(actions.deleteCondition(id)),
  createCondition: data => dispatch(actions.createCondition(data)),
  editCondition: (id, data) => dispatch(actions.editCondition(id, data)),
  getIdcConditions: pageRequest => dispatch(actions.getIdcConditions(pageRequest)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditConditionsModal);
