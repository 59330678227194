import React from 'react';

import { DateInput, Input } from '../../containers/Form';
import Strings from '../../Strings';
import { addListItem, editListItem, removeListItem } from './helpers';

function TransplantDetailsPage(props) {
  const { patient, highlightInvalidFields, validationErrors, fields, setPatient } = props;

  const transplantDetailsPage = (
    <React.Fragment>
      {patient.transplantDetails &&
        patient.transplantDetails.length > 0 &&
        patient.transplantDetails.map((t, i) => (
          <React.Fragment key={`transplantDet_${i}`}>
            {i > 0 && <div />}
            <Input
              name="organType"
              id="organType"
              label={Strings.capPatient.transplantOrganType}
              type="text"
              value={t.organType || ''}
              onChange={e => editListItem(e, 'transplantDetails', i, setPatient)}
              isRequired={fields.transplantDetails.required}
              disabled={fields.transplantDetails.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'transplantDetails' && v.index === i && v.field === 'organType',
                )?.errors || []
              }
            />
            <DateInput
              name="transplantDate"
              id="transplantDate"
              label={Strings.capPatient.transplantDate}
              placeholder="MM / DD / YYYY"
              value={t.transplantDate || ''}
              format="MM / dd / yyyy"
              onChange={e => editListItem({ target: { value: e, name: 'transplantDate' } }, 'transplantDetails', i, setPatient)}
              sRequired={fields.transplantDetails.required}
              disabled={fields.transplantDetails.readOnly}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'transplantDetails' && v.index === i && v.field === 'transplantDate',
                )?.errors || []
              }
            />
            <div className="button-container">
              <button
                className="minus-button high"
                onClick={() => removeListItem('transplantDetails', i, setPatient)}
                disabled={patient.transplantDetails.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container" style={{ gridColumnStart: 1 }}>
        <button className="plus-button" onClick={() => addListItem('transplantDetails', setPatient)}></button>
      </div>
    </React.Fragment>
  );

  return transplantDetailsPage;
}

export default TransplantDetailsPage;
