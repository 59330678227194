import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import TableWidget from '../component/TableWidget';
import { datesText, getProgramDisplayName, getProgramsText } from '../../../../utils/cmsPrograms';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

export const renderPatientMedication = m => `${m.name} ${m.ndc ? `(${m.ndc})` : ''} ${!_.isEmpty(m.prescriber) ? `Perscribed by: ${m.prescriber.firstName} ${m.prescriber.lastName}` : ''}`;
function MedicalHistory(props) {
  const { conditions, enrollment, medications, isLoading } = props;
  const data = [];
  if (enrollment?.enrollments) {
    Object.values(enrollment?.enrollments).sort((a, b) => a.subprogram === enrollment?.defaultProgram ? -1 : 1).forEach((program, i) => {
      data.push([Strings.program, getProgramDisplayName(program.program, program.subprogram)]);
      if (program.careProvider) {
        data.push([Strings.capPatient.careProvider, `${program.careProvider.name} (${program.careProvider.organization})`]);
      }
      if (program.enrollmentStart || program.enrollmentEnd) {
        data.push([Strings.enrollmentStartEnd, datesText(program)]);
      }
    });
  }
  if (conditions) {
    Object.values(conditions).forEach((v, i) => {
      data.push([Strings.condition, makeValid(v.title)]);
    });
  }
  if (medications) {
    medications.forEach(m => {
      data.push([Strings.medication, renderPatientMedication(m)]);
    });
  }
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  return (
    <TableWidget
      title={Strings.capPatient.medicalHistory}
      buttons={props.onEdit && canEdit ? [{ icon: 'edit', onClick: props.onEdit }] : undefined}
      data={data}
      isLoading={isLoading}
      firstColumnClassName="medical-history-col"
    />
  );
}

MedicalHistory.propTypes = {
  isLoading: PropTypes.any,
  conditions: PropTypes.any,
  enrollment: PropTypes.any,
  patientId: PropTypes.any,
  onEdit: PropTypes.func,
};
MedicalHistory.widgetId = 'MedicalHistoryWidget';

export default MedicalHistory;
