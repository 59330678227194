import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Wrapper, Button as MenuButton, MenuItem, Menu } from 'react-aria-menubutton';

import './WizardNavigation.scss';
import { actions } from './redux/actions';
import { getPagesDetails } from './ModalsMetadata';

const WizardNavigation = props => {
  const [isListOpen, setIsListOpen] = useState(false);

  const handleSelection = value => {
    props.onClick(value);
  };

  return (
    <Wrapper
      className="addMenu"
      onSelection={handleSelection}
      onMenuToggle={({ isOpen }) => {
        setIsListOpen(isOpen);
      }}
      closeOnSelection={false}
    >
      <MenuButton className="addMenu-button visibility-icon">
        <div className="filtervisibility-container relative">
          <div className={`${isListOpen ? 'filtervisibility-icon-on' : 'filtervisibility-icon'}`} />
        </div>
      </MenuButton>
      {props.pages?.length > 0 && (
        <Menu className="addMenu-menu visibility-icon">
          {props.pages.map(page => (
            <MenuItem className="addMenu-menuItem with-checkbox" value={page.id} id={page.id} key={page.id}>
              <div className={`missing-fields-count ${page.wrongFieldsCount ? 'not-valid' : 'valid'}`}>{page.wrongFieldsCount > 0 ? page.wrongFieldsCount : ''}</div>
              {page.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    pagesVisibility: getPagesDetails(state.modalsVisibility.pagesVisibility.find(v => v.id === props.modalId)),
  };
};

WizardNavigation.propTypes = {
  modalId: PropTypes.string,
  changePageVisibility: PropTypes.func,
  pagesVisibility: PropTypes.array,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: modalId => dispatch(actions.resetToDefault(modalId)),
  changePageVisibility: (modalId, pageId) => dispatch(actions.changePageVisibility(modalId, pageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardNavigation);
